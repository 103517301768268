// components/Common/AuthErrorHandler.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './AuthErrorHandler.module.css';

const AuthErrorHandler = () => {
    const [authError, setAuthError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Listen for auth events
        const handleNotLoggedIn = () => {
            setAuthError({
                type: 'not_logged_in',
                message: 'Please log in to continue',
            });

            // Auto-redirect after a short delay
            setTimeout(() => {
                navigate('/login', {
                    state: {
                        from: window.location.pathname,
                        authRequired: true
                    }
                });
                setAuthError(null);
            }, 3000);
        };

        const handleSessionExpired = () => {
            setAuthError({
                type: 'session_expired',
                message: 'Your session has expired. Please log in again.',
            });

            setTimeout(() => {
                navigate('/login', {
                    state: {
                        from: window.location.pathname,
                        sessionExpired: true
                    }
                });
                setAuthError(null);
            }, 3000);
        };

        const handleForbidden = () => {
            setAuthError({
                type: 'forbidden',
                message: 'You don\'t have permission to access this resource.',
            });

            setTimeout(() => {
                navigate('/websites');
                setAuthError(null);
            }, 3000);
        };

        // Add event listeners
        window.addEventListener('auth:notLoggedIn', handleNotLoggedIn);
        window.addEventListener('auth:sessionExpired', handleSessionExpired);
        window.addEventListener('auth:forbidden', handleForbidden);
        window.addEventListener('auth:unauthorized', handleNotLoggedIn);

        // Clean up
        return () => {
            window.removeEventListener('auth:notLoggedIn', handleNotLoggedIn);
            window.removeEventListener('auth:sessionExpired', handleSessionExpired);
            window.removeEventListener('auth:forbidden', handleForbidden);
            window.removeEventListener('auth:unauthorized', handleNotLoggedIn);
        };
    }, [navigate]);

    if (!authError) return null;

    return (
        <div className={styles.authErrorContainer}>
            <div className={styles.authErrorContent}>
                <div className={styles.authErrorIcon}>
                    {authError.type === 'forbidden' ? '🔒' : '⚠️'}
                </div>
                <p>{authError.message}</p>
                <p className={styles.redirectingText}>Redirecting...</p>
            </div>
        </div>
    );
};

export default AuthErrorHandler;
// src/components/auth/OAuthButtons.js
import React, { useState } from "react";
import styles from "./OAuthButtons.module.css";
import api, { authService } from "../../services/api";

const OAuthButtons = ({ className, buttonText = "Continue with" }) => {
    const [isLoading, setIsLoading] = useState(false);

    // Handle OAuth button click - fetch the OAuth URL from backend then redirect
    const handleOAuthClick = async (provider) => {
        try {
            setIsLoading(true);
            console.log(`${provider} OAuth button clicked`);

            let response;
            if (provider === 'Google') {
                response = await api.get('/auth/google/login');
            } else if (provider === 'GitHub') {
                response = await api.get('/auth/github/login');
            }

            // Check if the response has authorization_url
            if (response.data && response.data.authorization_url) {
                console.log(`Redirecting to ${provider} OAuth: ${response.data.authorization_url}`);
                window.location.href = response.data.authorization_url;
            } else {
                console.error(`Invalid ${provider} OAuth response:`, response.data);
                alert(`Failed to initiate ${provider} authentication. Please try again.`);
            }
        } catch (error) {
            console.error(`${provider} OAuth error:`, error);
            alert(`Error connecting to ${provider}. Please try again.`);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={`${styles.oauthContainer} ${className || ''}`}>
            {/* Google Login Button */}
            <button
                className={`${styles.oauthButton} ${styles.googleButton}`}
                onClick={() => handleOAuthClick('Google')}
                disabled={isLoading}
            >
                <span className={styles.oauthIcon}>
                    <svg viewBox="0 0 24 24" width="18" height="18">
                        <path
                            d="M12.545 10.239v3.821h5.445c-0.712 2.315-2.647 3.972-5.445 3.972-3.332 0-6.033-2.701-6.033-6.032s2.701-6.032 6.033-6.032c1.498 0 2.866 0.549 3.921 1.453l2.814-2.814c-1.798-1.677-4.198-2.701-6.735-2.701-5.523 0-9.996 4.473-9.996 9.996s4.473 9.996 9.996 9.996c8.396 0 10.826-7.883 9.994-11.659h-9.994z"
                            fill="#4285F4"
                        />
                    </svg>
                </span>
                {buttonText} Google
            </button>

            {/* GitHub Login Button */}
            <button
                className={`${styles.oauthButton} ${styles.githubButton}`}
                onClick={() => handleOAuthClick('GitHub')}
                disabled={isLoading}
            >
                <span className={styles.oauthIcon}>
                    <svg viewBox="0 0 24 24" width="18" height="18">
                        <path
                            d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"
                            fill="#24292e"
                        />
                    </svg>
                </span>
                {buttonText} GitHub
            </button>
        </div>
    );
};

export default OAuthButtons;
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';

const MainLayout = ({ children }) => {
    const location = useLocation();
    const isHomePage = location.pathname === '/';

    // On the home page, we render the Header and Footer but let the HomePage component 
    // handle the full-page layout with fixed positioning
    if (isHomePage) {
        return (
            <>
                <Header />
                {children}
                <Footer />
            </>
        );
    }

    // For all other pages, use the standard layout with scrolling
    return (
        <div className="app-wrapper">
            <Header />
            <main className="main-content">
                <div className="content-container">
                    {children}
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default MainLayout;
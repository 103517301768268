// services/assistantApi.js
import axios from 'axios';

const assistantApi = {
    sendMessage: async (message, websiteId, history) => {
        try {
            const token = localStorage.getItem('auth_token');
            const response = await axios.post('/api/assistant/installation-help', {
                message: message,
                website_id: websiteId || 'general',
                history: history || []
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error sending message:', error);
            throw error;
        }
    }
};

export default assistantApi;
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid,
    Tooltip, Legend, ResponsiveContainer, PieChart,
    Pie, Cell
} from 'recharts';
import styles from './Dashboard.module.css';
import dashboardService from '../../services/dashboardService';

// Define colors for charts
const COLORS = ['#2563eb', '#10b981', '#f59e0b', '#ef4444'];


const Dashboard = () => {
    const { id } = useParams(); // Get website ID from URL
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [websites, setWebsites] = useState([]);
    const [selectedWebsiteId, setSelectedWebsiteId] = useState(id || '');
    const [dashboardData, setDashboardData] = useState(null);
    const [timeRange, setTimeRange] = useState('7d');
    const [activeTab, setActiveTab] = useState(0);
    const [insights, setInsights] = useState([]);
    const [error, setError] = useState(null);

    // Additional states for specialized data
    const [outboundLinks, setOutboundLinks] = useState([]);
    const [errorPages, setErrorPages] = useState([]);
    const [downloads, setDownloads] = useState([]);
    const [customEvents, setCustomEvents] = useState([]);
    const [revenue, setRevenue] = useState(null);


    // Fetch user's websites on component mount
    useEffect(() => {
        const fetchWebsites = async () => {
            try {
                // Check if token exists before making the API call
                const token = localStorage.getItem('token');
                if (!token) {
                    console.log('No authentication token found - redirecting to login');
                    navigate('/login', { replace: true });
                    return;
                }

                setLoading(true);
                const websiteData = await dashboardService.getUserWebsites();

                if (websiteData && Array.isArray(websiteData)) {
                    setWebsites(websiteData);

                    // If no website ID in URL and we have websites, select the first one
                    if ((!id || id === 'undefined') && websiteData.length > 0) {
                        setSelectedWebsiteId(websiteData[0].id.toString());
                        navigate(`/dashboard/${websiteData[0].id}`, { replace: true });
                    } else if (id && id !== 'undefined') {
                        // If ID is provided, make sure it exists in the fetched websites
                        const websiteExists = websiteData.some(site => site.id.toString() === id.toString());
                        if (websiteExists) {
                            setSelectedWebsiteId(id);
                        } else if (websiteData.length > 0) {
                            // If ID doesn't exist but we have websites, select the first one
                            setSelectedWebsiteId(websiteData[0].id.toString());
                            navigate(`/dashboard/${websiteData[0].id}`, { replace: true });
                        }
                    }
                } else {
                    console.warn('Unexpected response format from API:', websiteData);
                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching websites:', error);

                // Handle authentication errors
                if (error.response && error.response.status === 401) {
                    console.log('Authentication failed - redirecting to login');
                    localStorage.removeItem('token');
                    navigate('/login', { replace: true });
                    return;
                }

                setError('Failed to load websites. Please try again later.');
                setLoading(false);
            }
        };

        fetchWebsites();
    }, [id, navigate]);

    // Fetch dashboard data when selectedWebsiteId or timeRange changes
    useEffect(() => {
        if (!selectedWebsiteId) return;

        const fetchDashboardData = async () => {
            const token = localStorage.getItem('token');
            console.log('Token when fetching dashboard data:', token ? 'Present' : 'Missing');

            if (!token) {
                console.log('No authentication token found for dashboard data - redirecting to login');
                navigate('/login', { replace: true });
                return;
            }
            try {
                // Fetch main dashboard data
                const data = await dashboardService.getDashboardData(selectedWebsiteId, timeRange);
                setDashboardData(data);

                // Generate insights based on the data
                if (data.insights && data.insights.length > 0) {
                    setInsights(data.insights);
                } else {
                    const insightsData = dashboardService.generateInsights(selectedWebsiteId, data);
                    setInsights(insightsData);
                }

                // Fetch additional data for specialized tabs
                if (data) {
                    // If the website has outbound links tracking enabled
                    if (data.outboundLinks) {
                        const linksData = await dashboardService.getOutboundLinks(selectedWebsiteId, timeRange);
                        setOutboundLinks(linksData);
                    }

                    // If the website has error pages tracking enabled
                    if (data.errorPages) {
                        const errorsData = await dashboardService.getErrorPages(selectedWebsiteId, timeRange);
                        setErrorPages(errorsData);
                    }

                    // If the website has downloads tracking enabled
                    if (data.downloads) {
                        const downloadsData = await dashboardService.getDownloads(selectedWebsiteId, timeRange);
                        setDownloads(downloadsData);
                    }

                    // If the website has custom events tracking enabled
                    if (data.customEvents) {
                        const eventsData = await dashboardService.getCustomEvents(selectedWebsiteId, timeRange);
                        setCustomEvents(eventsData);
                    }

                    // If the website has revenue tracking enabled
                    if (data.revenue) {
                        const revenueData = await dashboardService.getRevenue(selectedWebsiteId, timeRange);
                        setRevenue(revenueData);
                    }
                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
                setLoading(false);
            }
        };

        fetchDashboardData();
    }, [selectedWebsiteId, timeRange, navigate]);

    // Handle website selection change
    const handleWebsiteChange = (e) => {
        const websiteId = e.target.value;
        setSelectedWebsiteId(websiteId);
        navigate(`/dashboard/${websiteId}`);
    };

    // Handle time range change
    const handleTimeRangeChange = (range) => {
        setTimeRange(range);
    };

    // Show loading state
    if (loading) {
        return (
            <div className={styles.loadingContainer}>
                <div className={styles.spinnerContainer}>
                    <div className={styles.spinner}></div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className={styles.errorContainer}>
                <p className={styles.errorMessage}>{error}</p>
                <button
                    className={styles.actionButton}
                    onClick={() => window.location.reload()}
                >
                    Try Again
                </button>
            </div>
        );
    }


    // Show empty state if no websites
    if (websites.length === 0) {
        return (
            <div className={styles.container}>
                <div className={styles.card}>
                    <div className={styles.emptyState}>
                        <div className={styles.emptyStateIcon}>📊</div>
                        <h2 className={styles.emptyStateTitle}>No websites found</h2>
                        <p className={styles.emptyStateText}>
                            You haven't added any websites to track yet. Add your first website to start seeing analytics data.
                        </p>
                        <button className={styles.addWebsiteButton} onClick={() => navigate('/websites/add')}>
                            Add Website
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    // Show empty state if no data for selected website
    if (!dashboardData) {
        return (
            <div className={styles.container}>
                <div className={styles.websiteSelector}>
                    <label className={styles.selectorLabel}>Select Website</label>
                    <select
                        className={styles.selectDropdown}
                        value={selectedWebsiteId}
                        onChange={handleWebsiteChange}
                    >
                        {websites.map(website => (
                            <option key={website.id} value={website.id}>{website.name}</option>
                        ))}
                    </select>
                </div>

                <div className={styles.card}>
                    <div className={styles.emptyState}>
                        <div className={styles.emptyStateIcon}>📈</div>
                        <h2 className={styles.emptyStateTitle}>No data available</h2>
                        <p className={styles.emptyStateText}>
                            We don't have any analytics data for this website yet. Make sure you've installed the tracking script correctly.
                        </p>
                        <button className={styles.addWebsiteButton} onClick={() => navigate(`/websites/${selectedWebsiteId}`)}>
                            View Website Setup
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            {/* Website Selector */}
            <div className={styles.websiteSelector}>
                <label className={styles.selectorLabel}>Select Website</label>
                <select
                    className={styles.selectDropdown}
                    value={selectedWebsiteId}
                    onChange={handleWebsiteChange}
                >
                    {websites.map(website => (
                        <option key={website.id} value={website.id}>{website.name}</option>
                    ))}
                </select>
            </div>

            {/* Dashboard Header */}
            <div className={styles.dashboardHeader}>
                <div className={styles.headerText}>
                    <h1>{dashboardData.websiteName}</h1>
                    <div className={styles.headerSubtitle}>{dashboardData.domain}</div>
                </div>

                <div className={styles.timeRangeSelector}>
                    <button
                        onClick={() => handleTimeRangeChange('7d')}
                        className={timeRange === '7d' ? styles.timeRangeButtonActive : styles.timeRangeButton}
                    >
                        Last 7 days
                    </button>
                    <button
                        onClick={() => handleTimeRangeChange('30d')}
                        className={timeRange === '30d' ? styles.timeRangeButtonActive : styles.timeRangeButton}
                    >
                        Last 30 days
                    </button>
                    <button
                        onClick={() => handleTimeRangeChange('90d')}
                        className={timeRange === '90d' ? styles.timeRangeButtonActive : styles.timeRangeButton}
                    >
                        Last 90 days
                    </button>
                </div>
            </div>

            {/* Section Tabs */}
            <div className={styles.tabsContainer}>
                <ul className={styles.tabsList}>
                    <li
                        className={activeTab === 0 ? styles.tabItemActive : styles.tabItem}
                        onClick={() => setActiveTab(0)}
                    >
                        Overview
                    </li>
                    <li
                        className={activeTab === 1 ? styles.tabItemActive : styles.tabItem}
                        onClick={() => setActiveTab(1)}
                    >
                        Traffic Sources
                    </li>
                    <li
                        className={activeTab === 2 ? styles.tabItemActive : styles.tabItem}
                        onClick={() => setActiveTab(2)}
                    >
                        AI Insights
                    </li>
                    <li
                        className={activeTab === 3 ? styles.tabItemActive : styles.tabItem}
                        onClick={() => setActiveTab(3)}
                    >
                        Pages
                    </li>
                    <li
                        className={activeTab === 4 ? styles.tabItemActive : styles.tabItem}
                        onClick={() => setActiveTab(4)}
                    >
                        Devices
                    </li>
                    {revenue && (
                        <li
                            className={activeTab === 5 ? styles.tabItemActive : styles.tabItem}
                            onClick={() => setActiveTab(5)}
                        >
                            Revenue
                        </li>
                    )}
                </ul>
            </div>

            {/* Dashboard Content based on active tab */}
            {activeTab === 0 && (
                <>
                    {/* Key Metrics Cards */}
                    <div className={styles.gridContainer}>
                        {/* Visitors Card */}
                        <div className={styles.gridItemQuarter}>
                            <div className={styles.card}>
                                <div className={styles.metricCard}>
                                    <div className={styles.metricLabel}>Visitors</div>
                                    <div className={styles.metricValueContainer}>
                                        <div className={styles.metricValue}>
                                            {dashboardData.visitors.total.toLocaleString()}
                                        </div>
                                        <div className={dashboardData.visitors.trend > 0 ? styles.trendChipPositive : styles.trendChipNegative}>
                                            <span className={styles.trendIcon}>
                                                {dashboardData.visitors.trend > 0 ? '↑' : '↓'}
                                            </span>
                                            {Math.abs(dashboardData.visitors.trend)}%
                                        </div>
                                    </div>
                                    <div className={styles.miniChart}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <LineChart data={dashboardData.visitors.chartData}>
                                                <Line type="monotone" dataKey="value" stroke="#2563eb" dot={false} strokeWidth={2} />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Page Views Card */}
                        <div className={styles.gridItemQuarter}>
                            <div className={styles.card}>
                                <div className={styles.metricCard}>
                                    <div className={styles.metricLabel}>Page Views</div>
                                    <div className={styles.metricValueContainer}>
                                        <div className={styles.metricValue}>
                                            {dashboardData.pageViews.total.toLocaleString()}
                                        </div>
                                        <div className={dashboardData.pageViews.trend > 0 ? styles.trendChipPositive : styles.trendChipNegative}>
                                            <span className={styles.trendIcon}>
                                                {dashboardData.pageViews.trend > 0 ? '↑' : '↓'}
                                            </span>
                                            {Math.abs(dashboardData.pageViews.trend)}%
                                        </div>
                                    </div>
                                    <div className={styles.miniChart}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <LineChart data={dashboardData.pageViews.chartData}>
                                                <Line type="monotone" dataKey="value" stroke="#10b981" dot={false} strokeWidth={2} />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Bounce Rate Card */}
                        <div className={styles.gridItemQuarter}>
                            <div className={styles.card}>
                                <div className={styles.metricCard}>
                                    <div className={styles.metricLabel}>Bounce Rate</div>
                                    <div className={styles.metricValueContainer}>
                                        <div className={styles.metricValue}>
                                            {dashboardData.bounceRate.value}%
                                        </div>
                                        <div className={dashboardData.bounceRate.trend < 0 ? styles.trendChipPositive : styles.trendChipNegative}>
                                            <span className={styles.trendIcon}>
                                                {dashboardData.bounceRate.trend < 0 ? '↓' : '↑'}
                                            </span>
                                            {Math.abs(dashboardData.bounceRate.trend)}%
                                        </div>
                                    </div>
                                    <div className={styles.metricIcon}>
                                        {/* Replace with your icon of choice */}
                                        👤
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Average Session Card */}
                        <div className={styles.gridItemQuarter}>
                            <div className={styles.card}>
                                <div className={styles.metricCard}>
                                    <div className={styles.metricLabel}>Avg. Session Duration</div>
                                    <div className={styles.metricValueContainer}>
                                        <div className={styles.metricValue}>
                                            {dashboardData.avgSessionDuration.value}
                                        </div>
                                        <div className={dashboardData.avgSessionDuration.trend > 0 ? styles.trendChipPositive : styles.trendChipNegative}>
                                            <span className={styles.trendIcon}>
                                                {dashboardData.avgSessionDuration.trend > 0 ? '↑' : '↓'}
                                            </span>
                                            {Math.abs(dashboardData.avgSessionDuration.trend)}%
                                        </div>
                                    </div>
                                    <div className={styles.metricIcon}>
                                        {/* Replace with your icon of choice */}
                                        ⏱️
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Charts Row */}
                    <div className={styles.gridContainer}>
                        <div className={styles.gridItemHalf}>
                            <div className={styles.card}>
                                <div className={styles.cardContent}>
                                    <h2 className={styles.cardTitle}>Traffic Overview</h2>
                                    <div className={styles.chartContainer}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <LineChart
                                                data={dashboardData.visitors.chartData}
                                                margin={{ top: 10, right: 20, left: 0, bottom: 30 }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                                                <XAxis
                                                    dataKey="date"
                                                    tick={{ fontSize: 12 }}
                                                    padding={{ left: 20, right: 20 }}
                                                    tickMargin={10}
                                                />
                                                <YAxis
                                                    tick={{ fontSize: 12 }}
                                                    width={40}
                                                />
                                                <Tooltip />
                                                <Legend wrapperStyle={{ paddingTop: 10 }} />
                                                <Line
                                                    type="monotone"
                                                    dataKey="value"
                                                    name="Visitors"
                                                    stroke="#2563eb"
                                                    strokeWidth={2}
                                                    activeDot={{ r: 6 }}
                                                />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Device Breakdown Chart */}
                        <div className={styles.gridItemHalf}>
                            <div className={styles.card}>
                                <div className={styles.cardContent}>
                                    <h2 className={styles.cardTitle}>Device Breakdown</h2>
                                    <div className={styles.chartContainer}>
                                        <ResponsiveContainer width="100%" height="70%">
                                            <PieChart>
                                                <Pie
                                                    data={dashboardData.deviceBreakdown}
                                                    cx="50%"
                                                    cy="50%"
                                                    innerRadius={60}
                                                    outerRadius={90}
                                                    fill="#8884d8"
                                                    paddingAngle={5}
                                                    dataKey="value"
                                                    label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                                                >
                                                    {dashboardData.deviceBreakdown.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                    ))}
                                                </Pie>
                                                <Tooltip formatter={(value) => `${value}%`} />
                                            </PieChart>
                                        </ResponsiveContainer>
                                        <div className={styles.deviceIconContainer}>
                                            {dashboardData.deviceBreakdown.map((device, index) => (
                                                <div key={index} className={styles.deviceItem} style={{ color: COLORS[index % COLORS.length] }}>
                                                    <span className={styles.deviceIcon}>
                                                        {device.name === 'Desktop' ? '💻' : device.name === 'Mobile' ? '📱' : '📟'}
                                                    </span>
                                                    <span>{device.name}: {device.value}%</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {/* Traffic Sources Tab */}
            {activeTab === 1 && (
                <div className={styles.card}>
                    <div className={styles.cardContent}>
                        <h2 className={styles.cardTitle}>Traffic Sources</h2>

                        {/* Pie Chart for Traffic Sources */}
                        <div className={styles.chartContainer}>
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart>
                                    <Pie
                                        data={dashboardData.topReferrers}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        outerRadius={120}
                                        fill="#8884d8"
                                        dataKey="visits"
                                        nameKey="source"
                                        label={({ source, percent }) => `${source}: ${(percent * 100).toFixed(0)}%`}
                                    >
                                        {dashboardData.topReferrers.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip formatter={(value, name, props) => [`${value} visits`, props.payload.source]} />
                                    <Legend layout="vertical" align="right" verticalAlign="middle" />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>

                        <div>
                            <table className={styles.table}>
                                <thead>
                                    <tr className={styles.tableHeader}>
                                        <th>Source</th>
                                        <th>Visits</th>
                                        <th>Percentage</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dashboardData.topReferrers.map((referrer, index) => (
                                        <tr key={index} className={styles.tableRow}>
                                            <td>{referrer.source}</td>
                                            <td>{referrer.visits}</td>
                                            <td>{referrer.percentage}%</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}

            {/* AI Insights Tab */}
            {activeTab === 2 && (
                <div className={styles.card}>
                    <div className={styles.cardContent}>
                        <h2 className={styles.cardTitle}>AI-Powered Insights</h2>
                        {insights.length > 0 ? (
                            <div>
                                {insights.map((insight, index) => (
                                    <div
                                        key={index}
                                        className={
                                            insight.type === 'positive' ? styles.insightPositive :
                                                insight.type === 'negative' ? styles.insightNegative :
                                                    styles.insightSuggestion
                                        }
                                    >
                                        <h3 className={styles.insightTitle}>
                                            <span className={styles.insightIcon}>ℹ️</span>
                                            {insight.title}
                                        </h3>
                                        <p className={styles.insightText}>{insight.description}</p>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className={styles.emptyState}>
                                <p>Not enough data to generate insights yet. Check back after collecting more analytics data.</p>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {/* Pages Tab */}
            {activeTab === 3 && (
                <div className={styles.card}>
                    <div className={styles.cardContent}>
                        <h2 className={styles.cardTitle}>Page Performance</h2>
                        <div>
                            <table className={styles.table}>
                                <thead>
                                    <tr className={styles.tableHeader}>
                                        <th>Path</th>
                                        <th>Views</th>
                                        <th>Unique Visitors</th>
                                        <th>Percentage</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dashboardData.topPages.map((page, index) => (
                                        <tr key={index} className={styles.tableRow}>
                                            <td className={styles.tablePath}>{page.path}</td>
                                            <td>{page.views.toLocaleString()}</td>
                                            <td>{Math.round(page.views * 0.7).toLocaleString()}</td>
                                            <td>{page.percentage}%</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Error Pages Section (conditionally rendered) */}
                        {errorPages && errorPages.length > 0 && (
                            <div style={{ marginTop: '2rem' }}>
                                <h3 className={styles.cardTitle}>Error Pages</h3>
                                <table className={styles.table}>
                                    <thead>
                                        <tr className={styles.tableHeader}>
                                            <th>Path</th>
                                            <th>Error Views</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {errorPages.map((page, index) => (
                                            <tr key={index} className={styles.tableRow}>
                                                <td className={styles.tablePath}>{page.path}</td>
                                                <td>{page.views}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {/* Devices Tab */}
            {activeTab === 4 && (
                <div className={styles.card}>
                    <div className={styles.cardContent}>
                        <h2 className={styles.cardTitle}>Device Breakdown</h2>
                        <div className={styles.chartContainer}>
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart>
                                    <Pie
                                        data={dashboardData.deviceBreakdown}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={80}
                                        outerRadius={140}
                                        fill="#8884d8"
                                        paddingAngle={5}
                                        dataKey="value"
                                        label={({ name, value }) => `${name}: ${value}%`}
                                        labelLine={true}
                                    >
                                        {dashboardData.deviceBreakdown.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip formatter={(value) => `${value}%`} />
                                    <Legend
                                        layout="vertical"
                                        align="right"
                                        verticalAlign="middle"
                                        iconSize={12}
                                        iconType="circle"
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                        <div className={styles.deviceIconContainer}>
                            {dashboardData.deviceBreakdown.map((device, index) => (
                                <div
                                    key={index}
                                    className={styles.deviceItem}
                                    style={{ color: COLORS[index % COLORS.length] }}
                                >
                                    <span className={styles.deviceIcon}>
                                        {device.name === 'Desktop' ? '💻' : device.name === 'Mobile' ? '📱' : '📟'}
                                    </span>
                                    <span>
                                        <strong>{device.name}</strong>: {device.value}%
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            {/* Revenue Tab (conditionally rendered) */}
            {activeTab === 5 && revenue && (
                <div className={styles.card}>
                    <div className={styles.cardContent}>
                        <h2 className={styles.cardTitle}>Revenue Analysis</h2>

                        <div className={styles.gridContainer}>
                            <div className={styles.gridItemQuarter}>
                                <div className={styles.paperBlue}>
                                    <div className={styles.paperLabel}>Total Revenue</div>
                                    <div className={styles.paperValue}>
                                        {revenue.currency} {revenue.total_revenue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.gridItemQuarter}>
                                <div className={styles.paperGreen}>
                                    <div className={styles.paperLabel}>Transactions</div>
                                    <div className={styles.paperValue}>{revenue.transactions}</div>
                                </div>
                            </div>
                            <div className={styles.gridItemQuarter}>
                                <div className={styles.paperAmber}>
                                    <div className={styles.paperLabel}>Avg. Order Value</div>
                                    <div className={styles.paperValue}>
                                        {revenue.currency} {revenue.avg_order_value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.gridItemQuarter}>
                                <div className={styles.paperPurple}>
                                    <div className={styles.paperLabel}>Conversion Rate</div>
                                    <div className={styles.paperValue}>
                                        {(revenue.transactions / dashboardData.visitors.total * 100).toFixed(2)}%
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* We could add a revenue over time chart here if the API provides that data */}
                        <div className={styles.chartContainer} style={{ marginTop: '2rem' }}>
                            <p>Revenue tracking is active for this website. The data shown here represents e-commerce transactions tracked during the selected time period.</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dashboard;
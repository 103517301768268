import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import styles from './WebsiteEdit.module.css';
import { websiteService } from '../../services/api';

// SVG icons
const SaveIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
        <polyline points="17 21 17 13 7 13 7 21"></polyline>
        <polyline points="7 3 7 8 15 8"></polyline>
    </svg>
);

const CancelIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="15" y1="9" x2="9" y2="15"></line>
        <line x1="9" y1="9" x2="15" y2="15"></line>
    </svg>
);

const WebsiteEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [website, setWebsite] = useState(null);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    // Form state
    const [formData, setFormData] = useState({
        name: '',
        domain: '',
        url: '',
        timezone: 'UTC',
        analytics_type: 'custom',
        analytics_properties: {}
    });

    // Fetch website data
    useEffect(() => {
        const fetchWebsite = async () => {
            try {
                setLoading(true);
                const response = await websiteService.getWebsite(id);
                const websiteData = response.data;

                // Set website data
                setWebsite(websiteData);

                // Populate form data
                setFormData({
                    name: websiteData.name || '',
                    domain: websiteData.domain || '',
                    url: websiteData.url || '',
                    timezone: websiteData.timezone || 'UTC',
                    analytics_type: websiteData.analytics_type || 'custom',
                    analytics_properties: websiteData.analytics_properties || {}
                });

            } catch (err) {
                console.error('Error fetching website:', err);
                setError('Failed to load website data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchWebsite();
    }, [id]);

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setSaving(true);

            // Prepare update data - omit tracking options for now
            const updateData = {
                ...formData
            };

            // Send update request
            await websiteService.updateWebsite(id, updateData);

            // Show success message and navigate after delay
            setSuccess(true);
            setTimeout(() => {
                navigate(`/websites`);
            }, 1500);

        } catch (err) {
            console.error('Error updating website:', err);
            setError('Failed to update website. Please try again.');
        } finally {
            setSaving(false);
        }
    };

    // Handle cancel
    const handleCancel = () => {
        navigate(`/websites`);
    };

    if (loading) {
        return (
            <div className={styles.loaderContainer}>
                <div className={styles.loader}></div>
            </div>
        );
    }

    if (error && !website) {
        return (
            <div className={styles.errorContainer}>
                <div className={styles.errorMessage}>{error}</div>
                <button
                    className={styles.backButton}
                    onClick={() => navigate('/websites')}
                >
                    Back to Websites
                </button>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1 className={styles.title}>Edit Website</h1>
                <div className={styles.actions}>
                    <button
                        className={styles.cancelButton}
                        onClick={handleCancel}
                    >
                        <CancelIcon /> Cancel
                    </button>
                    <button
                        className={styles.saveButton}
                        onClick={handleSubmit}
                        disabled={saving}
                    >
                        <SaveIcon /> {saving ? 'Saving...' : 'Save Changes'}
                    </button>
                </div>
            </div>

            {success && (
                <div className={styles.successAlert}>
                    Website updated successfully! Redirecting...
                </div>
            )}

            {error && (
                <div className={styles.errorAlert}>
                    {error}
                </div>
            )}

            <div className={styles.formContainer}>
                <div className={styles.cardItem}>
                    <div className={styles.cardHeader}>Website Information</div>
                    <div className={styles.cardContent}>
                        <div className={styles.formGroup}>
                            <label className={styles.formLabel}>
                                Website Name *
                            </label>
                            <input
                                type="text"
                                className={styles.formInput}
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className={styles.formGroup}>
                            <label className={styles.formLabel}>
                                Domain *
                            </label>
                            <input
                                type="text"
                                className={styles.formInput}
                                name="domain"
                                value={formData.domain}
                                onChange={handleInputChange}
                                required
                            />
                            <div className={styles.formHelperText}>
                                Enter the domain without 'http://' or 'https://'
                            </div>
                        </div>

                        <div className={styles.formGroup}>
                            <label className={styles.formLabel}>
                                Website URL
                            </label>
                            <input
                                type="text"
                                className={styles.formInput}
                                name="url"
                                value={formData.url}
                                onChange={handleInputChange}
                            />
                            <div className={styles.formHelperText}>
                                Full URL including http:// or https://
                            </div>
                        </div>

                        <div className={styles.formGroup}>
                            <label className={styles.formLabel}>
                                Timezone
                            </label>
                            <select
                                className={styles.formSelect}
                                name="timezone"
                                value={formData.timezone}
                                onChange={handleInputChange}
                            >
                                <option value="UTC">UTC</option>
                                <option value="America/New_York">(GMT-04:00) America/New_York</option>
                                <option value="America/Chicago">(GMT-05:00) America/Chicago</option>
                                <option value="America/Denver">(GMT-06:00) America/Denver</option>
                                <option value="America/Los_Angeles">(GMT-07:00) America/Los_Angeles</option>
                                <option value="Europe/London">(GMT+00:00) Europe/London</option>
                                <option value="Europe/Paris">(GMT+01:00) Europe/Paris</option>
                                <option value="Asia/Tokyo">(GMT+09:00) Asia/Tokyo</option>
                            </select>
                        </div>

                        <div className={styles.formGroup}>
                            <label className={styles.formLabel}>
                                Analytics Type
                            </label>
                            <select
                                className={styles.formSelect}
                                name="analytics_type"
                                value={formData.analytics_type}
                                onChange={handleInputChange}
                            >
                                <option value="custom">Custom Analytics</option>
                                <option value="google_analytics">Google Analytics</option>
                            </select>
                        </div>

                        {formData.analytics_type === 'google_analytics' && (
                            <div className={styles.formGroup}>
                                <label className={styles.formLabel}>
                                    Google Analytics Tracking ID
                                </label>
                                <input
                                    type="text"
                                    className={styles.formInput}
                                    name="analytics_properties.tracking_id"
                                    value={formData.analytics_properties?.tracking_id || ''}
                                    onChange={(e) => {
                                        setFormData(prev => ({
                                            ...prev,
                                            analytics_properties: {
                                                ...prev.analytics_properties,
                                                tracking_id: e.target.value
                                            }
                                        }));
                                    }}
                                    placeholder="UA-XXXXXXXXX-X or G-XXXXXXXXXX"
                                />
                            </div>
                        )}
                    </div>
                </div>

                {/* Commenting out Tracking Options section for now */}
                {/* <div className={styles.cardItem}>
          <div className={styles.cardHeader}>Tracking Options</div>
          <div className={styles.cardContent}>
            <div className={styles.infoText}>
              Tracking options temporarily disabled while we update this feature.
            </div>
          </div>
        </div> */}
            </div>
        </div>
    );
};

export default WebsiteEdit;
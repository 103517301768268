// services/supportApi.js
import axios from 'axios';


const SUPPORT_API_URL = process.env.REACT_APP_SUPPORT_API_URL || 'https://analytics-hub.xyz';

const supportApi = axios.create({
    baseURL: SUPPORT_API_URL,
    headers: {
        'Content-Type': 'application/json',
    }
});

// Add a request interceptor to add the auth token to requests
supportApi.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('auth_token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

/**
 * Fetch all support tickets for the current user
 * @returns {Promise<Array>} List of support tickets
 */
export const fetchTickets = async () => {
    try {
        // Change api.get to supportApi.get
        const response = await supportApi.get('/api/tickets');
        return response.data;
    } catch (error) {
        console.error('Error fetching tickets:', error);
        throw error;
    }
};

export const fetchTicket = async (ticketId) => {
    try {
        const response = await supportApi.get(`/api/tickets/${ticketId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching ticket:', error);
        throw error;
    }
};

export const fetchTicketCollaboration = async (ticketId) => {
    try {
        const response = await supportApi.get(`/api/tickets/${ticketId}/collaboration`);
        return response.data;
    } catch (error) {
        console.error('Error fetching collaboration info:', error);
        throw error;
    }
};

export const uploadAttachment = async (ticketId, file) => {
    const formData = new FormData();
    formData.append('file', file);

    // Change api.post to supportApi.post
    const response = await supportApi.post(
        `/api/tickets/${ticketId}/attachments`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    );
    return response.data;
};

export const getTicketAttachments = async (ticketId) => {
    // Change api.get to supportApi.get
    const response = await supportApi.get(`/api/tickets/${ticketId}/attachments`);
    return response.data;
};

export const downloadAttachment = async (attachmentId) => {
    // We return the full URL for downloading
    return `${SUPPORT_API_URL}/api/attachments/${attachmentId}`;
};

export const deleteAttachment = async (attachmentId) => {
    // Change api.delete to supportApi.delete
    const response = await supportApi.delete(`/api/attachments/${attachmentId}`);
    return response.data;
};

export const getTicketMessages = async (ticketId) => {
    // Change api.get to supportApi.get
    const response = await supportApi.get(`/api/tickets/${ticketId}/messages`);
    return response.data;
};

export const sendMessage = async (ticketId, content) => {
    // Change api.post to supportApi.post
    const response = await supportApi.post(`/api/tickets/${ticketId}/messages`, {
        content: content
    });
    return response.data;
};

export const updateTicketStatus = async (ticketId, status) => {
    // Change api.patch to supportApi.patch
    const response = await supportApi.patch(`/api/tickets/${ticketId}/status`, {
        status: status
    });
    return response.data;
};

/**
 * Listen for webhook notifications from RYZE.ai
 * This sets up a polling mechanism to check for new messages
 * @param {string} ticketId - The ticket ID to listen for
 * @param {Function} onNewMessage - Callback for when new messages arrive
 * @returns {Function} - Function to stop listening
 */
export const listenForExternalMessages = (ticketId, onNewMessage) => {
    let lastMessageId = 0; // Start with 0 instead of Date.now()
    let polling = true;

    const checkForMessages = async () => {
        if (!polling) return;

        try {
            const messages = await getTicketMessages(ticketId);

            // First time initialization
            if (lastMessageId === 0 && messages.length > 0) {
                lastMessageId = Math.max(...messages.map(m => m.id));
                console.log(`Initialized lastMessageId to ${lastMessageId}`);
            }
            // Check for new messages on subsequent polls
            else if (messages.length > 0) {
                const latestId = Math.max(...messages.map(m => m.id));

                // If we have new messages
                if (latestId > lastMessageId) {
                    const newMessages = messages.filter(m => m.id > lastMessageId);
                    lastMessageId = latestId;

                    console.log(`Found ${newMessages.length} new messages`);

                    // Call the callback with each new message
                    newMessages.forEach(msg => {
                        if (onNewMessage && typeof onNewMessage === 'function') {
                            onNewMessage(msg);
                        }
                    });
                }
            }
        } catch (error) {
            console.error('Error checking for new messages:', error);
        }

        // Schedule next check if still polling
        if (polling) {
            setTimeout(checkForMessages, 5000); // Check every 5 seconds
        }
    };

    // Start polling immediately
    checkForMessages();

    // Return function to stop polling
    return () => {
        polling = false;
    };
};

/**
 * Check for new external messages and return them
 * @param {string} ticketId - The ticket ID to check
 * @param {number} afterId - Only get messages after this ID
 * @returns {Promise<Array>} - New messages
 */
export const checkForExternalMessages = async (ticketId, afterId = 0) => {
    try {
        const messages = await getTicketMessages(ticketId);
        return messages.filter(msg => msg.id > afterId);
    } catch (error) {
        console.error('Error checking for external messages:', error);
        return [];
    }
};

/**
 * Fetch tickets with filtering for new messages
 * @param {boolean} hasNewMessages - If true, only return tickets with new messages
 * @returns {Promise<Array>} - Filtered tickets
 */
export const fetchTicketsWithNewMessages = async (hasNewMessages = false) => {
    try {
        const response = await supportApi.get('/api/tickets', {
            params: { has_new_messages: hasNewMessages }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching tickets with new messages:', error);
        throw error;
    }
};

/**
 * Create a new support ticket
 * @param {Object} ticketData - The ticket data
 * @returns {Promise<Object>} - The created ticket
 */
export const createSupportTicket = async (ticketData) => {
    try {
        const response = await supportApi.post('/api/tickets', ticketData);
        return response.data;
    } catch (error) {
        console.error('Error creating support ticket:', error);
        throw error;
    }
};

/**
 * Forward a message to RYZE.ai via the Analytics Hub backend
 * @param {number} ticketId - The Analytics Hub ticket ID
 * @param {string} content - Message content to send
 * @returns {Promise<Object>} - Response from RYZE
 */
export const forwardMessageToRyze = async (ticketId, content) => {
    try {
        console.log(`Forwarding message to RYZE for ticket #${ticketId}`);

        // Get the collaboration info first to verify RYZE integration
        const collaboration = await fetchTicketCollaboration(ticketId);

        if (!collaboration || !collaboration.ryze_ticket_id) {
            console.error('No RYZE ticket ID found in collaboration data');
            throw new Error('This ticket is not connected to RYZE support');
        }

        const ryzeTicketId = collaboration.ryze_ticket_id;

        // Create the payload using the format expected by RYZE
        const payload = {
            content: content,
            sender_platform: "analytics-hub",
            sender_id: "user_analytics_hub", // You can customize this if needed
            metadata: {
                ticket_id: ticketId,
                timestamp: new Date().toISOString()
            }
        };

        // The API endpoint structure for reaching RYZE
        const endpoint = `/api/${ticketId}/forward-message-to-ryze`;

        // Make the API call via your backend
        const response = await supportApi.post(endpoint, payload);

        console.log('Message successfully forwarded to RYZE:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error forwarding message to RYZE:', error);
        throw error;
    }
};


export default supportApi;
// src/components/Auth/LoginPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink, useLocation } from 'react-router-dom';
import {
    Container, Box, Avatar, Typography, TextField, Button,
    Grid, Link, Divider, Paper, Alert, AlertTitle, CircularProgress
} from '@mui/material';
import { LockOutlined, GitHub, Google } from '@mui/icons-material';
import { useAuth } from '../../contexts/AuthContext';
// Add this import at the top of src/components/Auth/LoginPage.js
import { authService, api } from '../../services/api';
import { validateForm, handleOAuthError } from '../../utils/errorHandlers';

const LoginPage = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [formErrors, setFormErrors] = useState({});
    const [generalError, setGeneralError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const { login, error: authContextError } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    // ADD THIS NEW EFFECT FOR AUTH REDIRECTS
    useEffect(() => {
        // Check for auth redirect messages
        if (location.state?.authRequired) {
            setGeneralError('Please log in to access that page.');
        } else if (location.state?.sessionExpired) {
            setGeneralError('Your session has expired. Please log in again.');
        }
    }, [location.state]);
    // END OF NEW EFFECT

    // Check for success message from registration
    useEffect(() => {
        if (location.state?.message) {
            setSuccessMessage(location.state.message);
            // Clear the message after 5 seconds
            const timer = setTimeout(() => {
                setSuccessMessage('');
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [location.state]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Clear field-specific error when user starts typing
        if (formErrors[name]) {
            setFormErrors({ ...formErrors, [name]: '' });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Clear previous errors
        setFormErrors({});
        setGeneralError('');

        // Client-side validation
        const validation = validateForm(formData, 'login');
        if (!validation.isValid) {
            setFormErrors(validation.errors);
            return;
        }

        setIsLoading(true);

        try {
            const success = await login(formData.email, formData.password);
            if (success) {
                // MODIFY THIS PART TO USE THE REDIRECT URL
                if (location.state?.from) {
                    navigate(location.state.from);
                } else {
                    navigate('/websites');
                }
                // END OF MODIFIED PART
            }
        } catch (error) {
            console.error('Login error:', error);

            // Handle specific error types
            if (error.response) {
                // Server returned an error response
                if (error.response.status === 401) {
                    setGeneralError('Invalid email or password. Please try again.');
                } else if (error.response.data?.detail) {
                    setGeneralError(error.response.data.detail);
                } else {
                    setGeneralError('An error occurred during login. Please try again.');
                }
            } else if (error.request) {
                // Request made but no response received
                setGeneralError('Unable to connect to the server. Please check your internet connection.');
            } else {
                // Something else caused the error
                setGeneralError('An unexpected error occurred. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    // Update LoginPage.js to use the same approach as OAuthButtons.js
    const handleOAuthLogin = async (provider) => {
        setIsLoading(true);
        setGeneralError('');

        try {
            const response = provider === 'github'
                ? await authService.getGithubLoginUrl()
                : await authService.getGoogleLoginUrl();

            // Now we're handling the response from the API call
            if (response.data && response.data.authorization_url) {
                window.location.href = response.data.authorization_url;
            } else {
                setGeneralError(`Failed to initiate ${provider} authentication.`);
            }
        } catch (err) {
            const errorInfo = handleOAuthError(provider, err);
            setGeneralError(errorInfo.message);
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <Container component="main" maxWidth="sm">
            <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LockOutlined />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>

                    {successMessage && (
                        <Alert severity="success" sx={{ mt: 2, width: '100%' }}>
                            {successMessage}
                        </Alert>
                    )}

                    {(generalError || authContextError) && (
                        <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
                            <AlertTitle>Error</AlertTitle>
                            {generalError || authContextError}
                        </Alert>
                    )}

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={formData.email}
                            onChange={handleChange}
                            error={!!formErrors.email}
                            helperText={formErrors.email}
                            disabled={isLoading}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={formData.password}
                            onChange={handleChange}
                            error={!!formErrors.password}
                            helperText={formErrors.password}
                            disabled={isLoading}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={isLoading}
                        >
                            {isLoading ? <CircularProgress size={24} /> : 'Sign In'}
                        </Button>

                        <Divider sx={{ my: 2 }}>OR</Divider>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2, width: '100%' }}>
                            <Button
                                fullWidth
                                variant="outlined"
                                startIcon={<GitHub style={{ color: '#24292e' }} />}
                                onClick={() => handleOAuthLogin('github')}
                                sx={{
                                    borderColor: '#d0d7de',
                                    color: '#24292f',
                                    '&:hover': {
                                        borderColor: '#24292e',
                                        bgcolor: 'rgba(36, 41, 46, 0.04)'
                                    },
                                    py: 1,
                                    fontWeight: 500
                                }}
                                disabled={isLoading}
                            >
                                Continue with GitHub
                            </Button>
                            <Button
                                fullWidth
                                variant="outlined"
                                startIcon={<Google />}
                                onClick={() => handleOAuthLogin('google')}
                                sx={{
                                    borderColor: '#d0d7de',
                                    color: '#4285F4',
                                    '&:hover': {
                                        borderColor: '#4285F4',
                                        bgcolor: 'rgba(66, 133, 244, 0.04)'
                                    },
                                    py: 1,
                                    fontWeight: 500
                                }}
                                disabled={isLoading}
                            >
                                Continue with Google
                            </Button>
                        </Box>

                        <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
                            <Grid item>
                                <Link href="#" variant="body2" onClick={(e) => {
                                    e.preventDefault();
                                    // Add functionality for password reset
                                    alert('Password reset functionality will be implemented soon.');
                                }}>
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link component={RouterLink} to="/register" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Paper>
        </Container>
    );
};

export default LoginPage;
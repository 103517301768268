import axios from 'axios';

// Get base URLs from environment variables
const API_URL = process.env.REACT_APP_API_URL || 'https://analytics-hub.xyz/api';
const SUPPORT_API_URL = process.env.REACT_APP_SUPPORT_API_URL || 'https://analytics-hub.xyz';
const RYZE_API_URL = process.env.REACT_APP_RYZE_API_URL || 'https://ryze.ai/api';
const RYZE_API_KEY = process.env.REACT_APP_RYZE_API_KEY;
const RYZE_API_ENDPOINT = process.env.REACT_APP_RYZE_API_ENDPOINT || 'https://www.ryze.ai/api/external/support-tickets/';

/**
 * Submit a support ticket to both Analytics Hub and RYZE.ai
 * @param {Object} ticketData - The support ticket data
 * @returns {Promise} - Response from API
 */
export const submitSupportTicket = async (ticketData) => {
    try {
        // Get auth token
        const token = localStorage.getItem('auth_token');

        // Make sure we have the required fields for Analytics Hub
        const analyticsHubPayload = {
            email: ticketData.email,
            issue: ticketData.issue,
            platform: ticketData.platform || 'unknown',
            website_id: ticketData.website_id || '',
            website_domain: ticketData.website_domain || '',
            conversation_history: formatConversationHistory(ticketData.conversation_history || []),
            // Add metadata for RYZE.ai integration
            source: 'analytics-hub',
            service_provider: 'ryze.ai'
        };

        // Create headers with auth token
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };

        // Construct endpoint URL for Analytics Hub
        const analyticsHubEndpoint = `${SUPPORT_API_URL}/api/tickets`;

        console.log('Submitting support ticket to Analytics Hub:', analyticsHubEndpoint);
        console.log('Analytics Hub Payload:', JSON.stringify(analyticsHubPayload));

        // Step 1: Create ticket in Analytics Hub
        const analyticsHubResponse = await axios.post(
            analyticsHubEndpoint,
            analyticsHubPayload,
            { headers }
        );

        console.log('Analytics Hub ticket creation successful:', analyticsHubResponse.data);

        // Process the response and normalize the ID field
        const normalizedResponse = processTicketResponse(analyticsHubResponse.data);

        // Store ticket info in localStorage for later reference
        if (normalizedResponse.id) {
            localStorage.setItem('support_ticket_id', normalizedResponse.id);
            localStorage.setItem('support_ticket_timestamp', new Date().toISOString());
            localStorage.setItem('support_ticket_status', normalizedResponse.status || 'pending');
        }

        // Step 2: Create the same ticket in RYZE.ai
        try {
            if (RYZE_API_ENDPOINT && RYZE_API_KEY) {
                // Format data for RYZE.ai
                const ryzePayload = {
                    email: ticketData.email,
                    issue: ticketData.issue,
                    source: 'analytics-hub',
                    website_id: ticketData.website_id || null,
                    platform: ticketData.platform || 'web',
                    analytics_hub_id: normalizedResponse.id.toString(), // Link back to Analytics Hub ticket
                    conversation_history: formatConversationHistory(ticketData.conversation_history || [])
                };

                console.log('Submitting support ticket to RYZE.ai:', RYZE_API_ENDPOINT);
                console.log('RYZE.ai Payload:', JSON.stringify(ryzePayload));

                // Send to RYZE.ai
                const ryzeResponse = await axios.post(RYZE_API_ENDPOINT, ryzePayload, {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-API-Key': RYZE_API_KEY
                    }
                });

                console.log('RYZE.ai ticket creation successful:', ryzeResponse.data);

                // Store RYZE.ai ticket ID if available
                if (ryzeResponse.data && ryzeResponse.data.id) {
                    localStorage.setItem('ryze_ticket_id', ryzeResponse.data.id);
                }
            }
        } catch (ryzeError) {
            // Log error but don't fail the whole operation since Analytics Hub ticket was created
            console.error('Error creating ticket in RYZE.ai:', ryzeError);
            console.error('Will continue with Analytics Hub ticket only');
        }

        return { data: normalizedResponse };
    } catch (error) {
        console.error('Error submitting support ticket:', error);

        // Provide more detailed error information
        let errorMessage = 'Failed to submit support ticket';

        if (error.response) {
            // Server responded with an error
            console.error('Error response data:', error.response.data);
            console.error('Error response status:', error.response.status);

            if (error.response.data && error.response.data.detail) {
                errorMessage = error.response.data.detail;
            }
        } else if (error.request) {
            // Request was made but no response
            console.error('No response received:', error.request);
            errorMessage = 'No response received from server';
        } else {
            // Error in setting up the request
            console.error('Request setup error:', error.message);
            errorMessage = error.message;
        }

        // Add better console logging to help with debugging
        console.error(`Support ticket submission failed with error: ${errorMessage}`);

        // Rethrow with better error info
        const enhancedError = new Error(errorMessage);
        enhancedError.originalError = error;
        throw enhancedError;
    }
};

/**
 * Process and normalize the ticket response
 * @param {Object} responseData - The API response data
 * @returns {Object} - Normalized response data
 */
function processTicketResponse(responseData) {
    if (!responseData) return {};

    // Create a normalized copy of the response
    const normalizedResponse = { ...responseData };

    // Ensure we handle the response correctly, especially if ID is a string
    if (typeof normalizedResponse.id === 'undefined' && normalizedResponse.ticket_id) {
        // Backend might be returning ticket_id instead of id
        normalizedResponse.id = String(normalizedResponse.ticket_id);
    }

    // Ensure status is set
    if (!normalizedResponse.status) {
        normalizedResponse.status = 'pending';
    }

    // Add RYZE.ai specific info if not present
    if (!normalizedResponse.service_provider) {
        normalizedResponse.service_provider = 'ryze.ai';
    }

    return normalizedResponse;
}

/**
 * Format conversation history to match the expected schema
 * @param {Array} conversation - The conversation history array
 * @returns {Array} - Formatted conversation history
 */
function formatConversationHistory(conversation) {
    if (!Array.isArray(conversation)) {
        return [];
    }

    return conversation.map(msg => {
        // Ensure each message has the required fields
        return {
            role: msg.role || 'user',
            content: msg.content || '',
            timestamp: msg.timestamp || new Date().toISOString()
        };
    });
}
/**
 * Get the status of a support ticket from RYZE.ai
 * @param {string} ticketId - The ticket ID
 * @returns {Promise} - The API response
 */
export const getSupportTicketStatus = async (ticketId) => {
    try {
        const token = localStorage.getItem('auth_token');

        const response = await axios.get(
            `${SUPPORT_API_URL}/api/tickets/${ticketId}/status`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );

        if (response.data && response.data.status) {
            // Update the stored status
            localStorage.setItem('support_ticket_status', response.data.status);
        }

        return response;
    } catch (error) {
        console.error('Error getting ticket status:', error);
        throw error;
    }
};

/**
 * Check if the support API is available
 * @returns {Promise<boolean>} - True if the API is available
 */
export const checkSupportApiStatus = async () => {
    try {
        const response = await axios.get(`${API_URL}/health`, { timeout: 5000 });
        return response.status === 200;
    } catch (error) {
        console.error('Support API check failed:', error);
        return false;
    }
};
// services/api.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://analytics-hub.xyz/api';

// Create an axios instance need to deploy
const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    // Add timeout to prevent long-hanging requests
    timeout: 15000,
});

export const fetchTicket = async (ticketId) => {
    const response = await api.get(`/support/tickets/${ticketId}`);
    return response.data;
};

export const fetchTicketCollaboration = async (ticketId) => {
    const response = await api.get(`/support/tickets/${ticketId}/collaboration`);
    return response.data;
};

export const createSupportTicket = async (ticketData) => {
    const response = await api.post('/support/tickets', ticketData);
    return response.data;
};

// Add a request interceptor to add the auth token to requests
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('auth_token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add this to your api.js file or update your existing interceptors

// Refine the response interceptor to better handle auth errors
api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        // If there's no response, it might be a network error
        if (!error.response) {
            console.error('Network error or server not responding');
            return Promise.reject({
                ...error,
                isNetworkError: true,
                message: 'Unable to connect to the server. Please check your internet connection.'
            });
        }

        // Handle 401 Unauthorized
        if (error.response.status === 401) {
            // If we're trying to access the auth endpoints, don't retry
            if (originalRequest.url.includes('/auth/login') ||
                originalRequest.url.includes('/auth/register') ||
                originalRequest.url.includes('/auth/refresh-token')) {
                return Promise.reject({
                    ...error,
                    isAuthError: true,
                    authErrorType: 'invalid_credentials'
                });
            }

            // Check if we have a token before attempting refresh
            const token = localStorage.getItem('auth_token');
            const refreshToken = localStorage.getItem('refresh_token');

            // Not logged in at all - no tokens
            if (!token && !refreshToken) {
                // Dispatch event for UI to handle (e.g., show login prompt)
                window.dispatchEvent(new CustomEvent('auth:notLoggedIn'));

                return Promise.reject({
                    ...error,
                    isAuthError: true,
                    authErrorType: 'not_logged_in',
                    message: 'Please log in to access this resource'
                });
            }

            // Token refresh logic (your existing code)
            if (!originalRequest._retry && refreshToken) {
                originalRequest._retry = true;
                try {
                    // Rest of your token refresh logic...
                } catch (refreshError) {
                    // Your existing refresh error handling...
                }
            }
        }

        // Handle other status codes
        if (error.response.status === 403) {
            window.dispatchEvent(new CustomEvent('auth:forbidden'));
            return Promise.reject({
                ...error,
                isForbiddenError: true,
                message: 'You do not have permission to access this resource'
            });
        }

        if (error.response.status === 404) {
            return Promise.reject({
                ...error,
                isNotFoundError: true,
                message: 'The requested resource was not found'
            });
        }

        if (error.response.status >= 500) {
            return Promise.reject({
                ...error,
                isServerError: true,
                message: 'Server error. Please try again later.'
            });
        }

        return Promise.reject(error);
    }
);

// Auth service functions
export const authService = {
    login: async (email, password) => {
        try {
            // Create form data as expected by OAuth2PasswordRequestForm
            const formData = new URLSearchParams();
            formData.append('username', email); // Backend expects 'username' field
            formData.append('password', password);

            const response = await api.post('/auth/login', formData.toString(), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });

            if (response.data.access_token) {
                localStorage.setItem('auth_token', response.data.access_token);
                // Store refresh token if your API provides one
                if (response.data.refresh_token) {
                    localStorage.setItem('refresh_token', response.data.refresh_token);
                }

                // Store login timestamp for token expiry calculations
                localStorage.setItem('auth_timestamp', Date.now().toString());
            }

            return response.data;
        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    },

    register: async (userData) => {
        try {
            return await api.post('/auth/register', userData);
        } catch (error) {
            console.error('Registration error:', error);
            throw error;
        }
    },

    logout: () => {
        localStorage.removeItem('auth_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('auth_timestamp');
    },

    getCurrentUser: async () => {
        return api.get('/auth/me');
    },

    // OAuth helper methods with direct return of URLs
    getGithubLoginUrl: () => {
        return api.get('/auth/github/login');
    },

    getGoogleLoginUrl: () => {
        return api.get('/auth/google/login');
    },

    // Helper method for OAuth callback handling
    handleOAuthCallback: async (token) => {
        if (token) {
            localStorage.setItem('auth_token', token);
            localStorage.setItem('auth_timestamp', Date.now().toString());

            // After setting the token, try to fetch the current user
            // to verify the token is valid and populate user data
            try {
                const userResponse = await api.get('/auth/me', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (userResponse.data) {
                    return true;
                }
                return false;
            } catch (error) {
                console.error('Error fetching user after OAuth:', error);
                // If we can't fetch the user, the token might be invalid
                localStorage.removeItem('auth_token');
                localStorage.removeItem('auth_timestamp');
                return false;
            }
        }
        return false;
    }
};

// Website service functions
export const websiteService = {
    // Existing methods
    getUserWebsites: () => {
        return api.get('/websites');
    },

    getWebsite: (id) => {
        return api.get(`/websites/${id}`);
    },

    createWebsite: (websiteData) => {
        return api.post('/websites', websiteData);
    },

    updateWebsite: (id, websiteData) => {
        return api.put(`/websites/${id}`, websiteData);
    },

    deleteWebsite: (id) => {
        return api.delete(`/websites/${id}`);
    },

    // Add new method for status updates specifically
    updateWebsiteStatus: (id, statusData) => {
        return api.patch(`/websites/${id}/status`, statusData);
    }
};

// Export the API instance as default
export default api;
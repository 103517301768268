import React from 'react';
import styles from './QuickReplies.module.css';

const QuickReplies = ({ actions, onActionClick }) => {
    if (!actions || actions.length === 0) {
        return null;
    }

    return (
        <div className={styles.container}>
            <div className={styles.quickReplies}>
                {actions.map((action, index) => (
                    <button
                        key={index}
                        className={styles.quickReplyButton}
                        onClick={() => onActionClick(action)}
                    >
                        {action.text}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default QuickReplies;
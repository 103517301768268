// components/Support/SupportForm.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createSupportTicket } from '../../services/supportApi';
import styles from './SupportForm.module.css';

// MUI components
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
  Alert,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Chip,
  Divider
} from '@mui/material';
import { ArrowBack as ArrowLeft } from '@mui/icons-material';

// RYZE.ai Logo component
const RyzeLogo = () => (
  <Box
    component="span"
    sx={{
      fontWeight: 'bold',
      fontSize: '1.2rem',
      color: '#2196f3',
      display: 'inline-flex',
      alignItems: 'center'
    }}
  >
    <Box
      component="span"
      sx={{
        bgcolor: '#2196f3',
        color: 'white',
        borderRadius: '4px',
        px: 0.8,
        py: 0.3,
        mr: 0.5,
        fontSize: '0.9rem'
      }}
    >
      RYZE
    </Box>
    .ai
  </Box>
);

const SupportForm = ({ onSubmitSuccess, onCancel }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    issue: '',
    platform: 'Web',
    website: ''
  });

  const [websites, setWebsites] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  // Fetch user's websites for the dropdown
  useEffect(() => {
    const fetchWebsites = async () => {
      try {
        // This would be replaced with your actual websites API
        const token = localStorage.getItem('auth_token');
        if (!token) {
          setWebsites([]);
          return;
        }

        const response = await fetch('/api/websites', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.ok) {
          const data = await response.json();
          setWebsites(Array.isArray(data) ? data : []);
        } else {
          console.error('Error fetching websites:', response.status);
          setWebsites([]);
        }
      } catch (error) {
        console.error('Error fetching websites:', error);
        setWebsites([]);
      }
    };

    fetchWebsites();

    // Pre-fill email from localStorage if available
    const userEmail = localStorage.getItem('user_email');
    if (userEmail) {
      setFormData(prev => ({ ...prev, email: userEmail }));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // Clear validation error when field is modified
    if (validationErrors[name]) {
      setValidationErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
    }

    if (!formData.issue.trim()) {
      errors.issue = 'Please describe your issue';
    } else if (formData.issue.length < 10) {
      errors.issue = 'Please provide more details about your issue';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      setIsSubmitting(true);
      setError('');

      const response = await createSupportTicket({
        email: formData.email,
        issue: formData.issue,
        platform: formData.platform,
        website_id: formData.website ? parseInt(formData.website, 10) : null
      });

      setIsSubmitting(false);

      if (response && response.id) {
        // Display a success toast notification
        toast.success('Support ticket created successfully!');
        setSuccess(true);

        // Reset the form
        setFormData({
          email: formData.email, // Keep the email
          issue: '',
          platform: 'Web',
          website: ''
        });

        if (onSubmitSuccess) {
          onSubmitSuccess(response.id);
        }

        // Redirect to websites page after 2 seconds
        setTimeout(() => {
          navigate('/websites');
        }, 2000);
      } else {
        setError('Something went wrong. Please try again.');
        toast.error('Failed to create support ticket');
      }
    } catch (err) {
      setIsSubmitting(false);
      setError(err.response?.data?.detail || 'Failed to create support ticket');
      toast.error(err.response?.data?.detail || 'Failed to create support ticket');
      console.error('Error submitting support ticket:', err);
    }
  };

  const handleGoBack = () => {
    if (typeof onCancel === 'function') {
      onCancel();
    } else {
      // Navigate back to the support tickets page
      navigate('/support');
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 8 }}>
      <Box sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
        <Button
          startIcon={<ArrowLeft />}
          onClick={handleGoBack}
          sx={{ textTransform: 'none' }}
        >
          Back to tickets
        </Button>
      </Box>

      <Paper
        elevation={3}
        sx={{
          p: 4,
          borderRadius: 2,
          boxShadow: '0 8px 40px rgba(0,0,0,0.12)'
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ mb: 1 }}>
          Create Support Ticket
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            mb: 3
          }}
        >
          <Typography variant="body2" color="text.secondary">
            Powered by
          </Typography>
          <RyzeLogo />
          <Chip
            size="small"
            label="Premium Tech Support"
            sx={{
              bgcolor: 'rgba(33, 150, 243, 0.1)',
              color: 'primary.main',
              fontSize: '0.7rem',
              height: '20px'
            }}
          />
        </Box>

        <Alert severity="info" sx={{ mb: 3 }}>
          Your request will be handled by RYZE.ai, our affiliated tech support service. Our team of developers will review your issue and respond promptly.
        </Alert>

        <form onSubmit={handleSubmit}>
          <TextField
            label="Email Address"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            variant="outlined"
            sx={{ mb: 2 }}
            error={!!validationErrors.email}
            helperText={validationErrors.email}
          />

          <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 2,
            mb: 2
          }}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{ minWidth: { xs: '100%', sm: '50%' } }}
            >
              <InputLabel id="platform-label">Platform</InputLabel>
              <Select
                labelId="platform-label"
                name="platform"
                value={formData.platform}
                onChange={handleChange}
                label="Platform"
              >
                <MenuItem value="Web">Web</MenuItem>
                <MenuItem value="WordPress">WordPress</MenuItem>
                <MenuItem value="Shopify">Shopify</MenuItem>
                <MenuItem value="Wix">Wix</MenuItem>
                <MenuItem value="Webflow">Webflow</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              fullWidth
              variant="outlined"
              sx={{ minWidth: { xs: '100%', sm: '50%' } }}
            >
              <InputLabel id="website-label">Website</InputLabel>
              <Select
                labelId="website-label"
                name="website"
                value={formData.website}
                onChange={handleChange}
                label="Website"
              >
                <MenuItem value="">-- Select website --</MenuItem>
                {Array.isArray(websites) && websites.map(site => (
                  <MenuItem key={site.id} value={site.id.toString()}>
                    {site.domain || site.name || `Site #${site.id}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <TextField
            label="Describe your issue"
            name="issue"
            value={formData.issue}
            onChange={handleChange}
            fullWidth
            required
            variant="outlined"
            multiline
            rows={6}
            placeholder="Please provide as much detail as possible about your issue or question"
            sx={{ mt: 2, mb: 3 }}
            error={!!validationErrors.issue}
            helperText={validationErrors.issue}
          />

          {error && (
            <Alert severity="error" sx={{ mt: 2, mb: 3 }}>
              {error}
            </Alert>
          )}

          <Box sx={{
            mt: 4,
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2
          }}>
            <Button
              type="button"
              variant="outlined"
              onClick={handleGoBack}
              disabled={isSubmitting}
              sx={{
                py: 1.5,
                borderRadius: '8px',
                fontSize: '1rem',
              }}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={isSubmitting}
              sx={{
                py: 1.5,
                px: 4,
                borderRadius: '8px',
                fontSize: '1rem',
                minWidth: '200px',
              }}
            >
              {isSubmitting ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Create Ticket'
              )}
            </Button>
          </Box>

          <Box sx={{ mt: 3, textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              By submitting this form, you agree to share your information with RYZE.ai for support purposes.
            </Typography>
          </Box>
        </form>
      </Paper>

      {success && (
        <Alert severity="success" sx={{ mt: 2 }}>
          Your support request has been submitted to RYZE.ai and is now pending. You'll be redirected to your websites.
        </Alert>
      )}
    </Container>
  );
};

export default SupportForm;
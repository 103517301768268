// components/Guides/InstallationGuides.js
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
    Container,
    Typography,
    Paper,
    Tabs,
    Tab,
    Box,
    Link,
    Divider,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    alpha
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import CodeIcon from '@mui/icons-material/Code';
import WebIcon from '@mui/icons-material/Web';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import StorefrontIcon from '@mui/icons-material/Storefront';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import HelpIcon from '@mui/icons-material/Help';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LanguageIcon from '@mui/icons-material/Language'; // Using Language icon for WordPress instead
import { websiteService } from '../../services/api';

// Styled components for consistent styling
const CodeBox = styled(Box)(({ theme }) => ({
    backgroundColor: '#3E5B85', // Same light blue background used in setup page
    borderRadius: '8px',
    color: '#f8f8f2',
    fontFamily: 'monospace',
    fontSize: '0.875rem',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(5), // Extra padding at top to make room for button
    position: 'relative',
    overflowX: 'auto',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    boxShadow: 'inset 0 2px 8px rgba(0,0,0,0.2)',
}));

const CopyButton = styled(Button)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 2,
    minWidth: 'auto',
    padding: theme.spacing(0.5, 1.5),
}));

const InstallationGuides = ({ websiteId, initialDomain }) => {
    const [tabValue, setTabValue] = useState(0);
    const [copied, setCopied] = useState(false);
    const [codeToCopy, setCodeToCopy] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    // Use params if websiteId wasn't passed as a prop
    const id = websiteId || params.id;

    // Get domain from query params if available
    const queryParams = new URLSearchParams(location.search);
    const domainFromURL = queryParams.get('domain');

    // State for website data
    const [website, setWebsite] = useState(null);
    const [currentDomain, setCurrentDomain] = useState(initialDomain || domainFromURL || 'your-domain.com');

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleCopyCode = (code) => {
        navigator.clipboard.writeText(code);
        setCodeToCopy(code);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    useEffect(() => {
        if (id) {
            const fetchWebsite = async () => {
                try {
                    setLoading(true);
                    const response = await websiteService.getWebsite(id);
                    console.log("API Response:", response);

                    const websiteData = response.data;
                    console.log("Website Data:", websiteData);
                    console.log("Domain from API:", websiteData.domain);

                    setWebsite(websiteData);

                    // Make sure to explicitly set the domain here
                    if (websiteData.domain) {
                        setCurrentDomain(websiteData.domain);
                        console.log("Setting domain to:", websiteData.domain);
                    }

                    setLoading(false);
                } catch (err) {
                    console.error('Error fetching website:', err);
                    setLoading(false);
                }
            };

            fetchWebsite();
        } else {
            console.log("No websiteId provided, using fallback domain");
        }
    }, [id]);

    // Also add this console.log to see what domain is being used in the tracking code
    console.log("Current domain used for code examples:", currentDomain);

    // Then in your generateTrackingCode function, make sure the domain is being used
    const generateTrackingCode = (options = {}) => {
        console.log("Generating tracking code with domain:", currentDomain);

        let code = `<script defer data-domain="${currentDomain}" `;

        // Add data attributes based on options
        if (options.outboundLinks) code += 'data-track-outbound="true" ';
        if (options.fileDownloads) code += 'data-track-downloads="true" ';
        if (options.errorPages) code += 'data-track-errors="true" ';
        if (options.hashedPaths) code += 'data-track-hashed="true" ';
        if (options.customEvents) code += 'data-track-events="true" ';
        if (options.customProperties) code += 'data-track-props="true" ';
        if (options.ecommerceRevenue) code += 'data-track-revenue="true" ';

        code += `src="https://analytics-hub.xyz/api/tracking/script.js"></script>`;
        return code;
    };

    // Generate code examples with the current domain
    const trackingCode = generateTrackingCode();

    const reactCode = `// In your main App component or index.js
import { useEffect } from 'react';

useEffect(() => {
  const script = document.createElement('script');
  script.defer = true;
  script.setAttribute('data-domain', '${currentDomain}');
  script.src = 'https://analytics-hub.xyz/api/tracking/script.js';
  document.head.appendChild(script);
  
  return () => {
    document.head.removeChild(script);
  };
}, []);`;

    const vueCode = `// In your main.js or App.vue
mounted() {
  const script = document.createElement('script');
  script.defer = true;
  script.setAttribute('data-domain', '${currentDomain}');
  script.src = 'https://analytics-hub.xyz/api/tracking/script.js';
  document.head.appendChild(script);
}`;

    const angularCode = `// In your app.component.ts
ngOnInit() {
  const script = document.createElement('script');
  script.defer = true;
  script.setAttribute('data-domain', '${currentDomain}');
  script.src = 'https://analytics-hub.xyz/api/tracking/script.js';
  document.head.appendChild(script);
}`;

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`installation-tabpanel-${index}`}
                aria-labelledby={`installation-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    };

    // Enhanced Code Block component with new styling
    const CodeBlock = ({ code }) => (
        <CodeBox>
            <CopyButton
                variant="contained"
                size="small"
                color={codeToCopy === code && copied ? "success" : "primary"}
                onClick={() => handleCopyCode(code)}
                startIcon={codeToCopy === code && copied ? <CheckCircleIcon fontSize="small" /> : <ContentCopyIcon fontSize="small" />}
            >
                {codeToCopy === code && copied ? 'Copied' : 'Copy'}
            </CopyButton>
            <pre style={{
                margin: 0,
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all',
                fontFamily: 'inherit',
            }}>
                {code}
            </pre>
        </CodeBox>
    );

    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 8 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                <IconButton onClick={() => navigate(-1)} sx={{ mr: 2 }}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h4" component="h1">
                    Installation Guides
                </Typography>
            </Box>

            <Paper sx={{ mb: 4, borderRadius: 2, boxShadow: '0 4px 20px rgba(0,0,0,0.08)' }}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="installation guide tabs"
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        '& .MuiTab-root': {
                            minHeight: 64,
                            py: 1
                        }
                    }}
                >
                    <Tab icon={<CodeIcon />} label="General" />
                    <Tab icon={<LanguageIcon />} label="WordPress" />
                    <Tab icon={<ShoppingCartIcon />} label="Shopify" />
                    <Tab icon={<WebIcon />} label="Wix" />
                    <Tab icon={<WebAssetIcon />} label="Squarespace" />
                    <Tab icon={<IntegrationInstructionsIcon />} label="SPAs" />
                    <Tab icon={<StorefrontIcon />} label="GTM" />
                    <Tab icon={<HelpIcon />} label="Troubleshooting" />
                </Tabs>

                <TabPanel value={tabValue} index={0}>
                    <Typography variant="h6" gutterBottom>
                        General Installation Instructions
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Follow these steps to install Analytics Hub on any website:
                    </Typography>
                    <Box sx={{ pl: 2, mb: 3 }}>
                        <Typography variant="body1" component="div">
                            1. <strong>Copy your unique tracking code</strong> from the setup page
                        </Typography>
                        <Typography variant="body1" component="div">
                            2. <strong>Paste the code</strong> into the <code>&lt;head&gt;</code> section of your website
                        </Typography>
                        <Typography variant="body1" component="div">
                            3. <strong>Verify installation</strong> by clicking the "Start collecting data" button
                        </Typography>
                    </Box>
                    <Typography variant="body1" paragraph>
                        Your tracking code looks like this:
                    </Typography>
                    <CodeBlock code={trackingCode} />
                    <Typography variant="body1">
                        This needs to be added to every page you want to track on your website. The best place is inside the <code>&lt;head&gt;</code> tag.
                    </Typography>
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                    <Typography variant="h6" gutterBottom>
                        WordPress Installation
                    </Typography>

                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 3 }}>
                        Method 1: Using a Plugin
                    </Typography>
                    <Box sx={{ pl: 2, mb: 3 }}>
                        <Typography variant="body1" component="div">
                            1. Install and activate the "Header and Footer Scripts" plugin
                        </Typography>
                        <Typography variant="body1" component="div">
                            2. Go to Settings &gt; Header and Footer Scripts
                        </Typography>
                        <Typography variant="body1" component="div">
                            3. Paste your tracking code in the "Scripts in Header" section
                        </Typography>
                        <Typography variant="body1" component="div">
                            4. Save changes
                        </Typography>
                    </Box>

                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 3 }}>
                        Method 2: Using a Theme
                    </Typography>
                    <Box sx={{ pl: 2, mb: 3 }}>
                        <Typography variant="body1" component="div">
                            1. Go to Appearance &gt; Theme Editor
                        </Typography>
                        <Typography variant="body1" component="div">
                            2. Select your theme's header.php file
                        </Typography>
                        <Typography variant="body1" component="div">
                            3. Paste the tracking code just before the closing <code>&lt;/head&gt;</code> tag
                        </Typography>
                        <Typography variant="body1" component="div">
                            4. Update file
                        </Typography>
                    </Box>

                    <CodeBlock code={trackingCode} />
                </TabPanel>

                <TabPanel value={tabValue} index={2}>
                    <Typography variant="h6" gutterBottom>
                        Shopify Installation
                    </Typography>
                    <Box sx={{ pl: 2, mb: 3 }}>
                        <Typography variant="body1" component="div">
                            1. Go to Online Store &gt; Themes
                        </Typography>
                        <Typography variant="body1" component="div">
                            2. Click "Actions" and select "Edit code"
                        </Typography>
                        <Typography variant="body1" component="div">
                            3. Open the theme.liquid file
                        </Typography>
                        <Typography variant="body1" component="div">
                            4. Paste your tracking code just before the closing <code>&lt;/head&gt;</code> tag
                        </Typography>
                        <Typography variant="body1" component="div">
                            5. Save changes
                        </Typography>
                    </Box>
                    <CodeBlock code={trackingCode} />
                </TabPanel>

                <TabPanel value={tabValue} index={3}>
                    <Typography variant="h6" gutterBottom>
                        Wix Installation
                    </Typography>
                    <Box sx={{ pl: 2, mb: 3 }}>
                        <Typography variant="body1" component="div">
                            1. Go to Settings &gt; Advanced &gt; Custom Code
                        </Typography>
                        <Typography variant="body1" component="div">
                            2. Click "Add Custom Code"
                        </Typography>
                        <Typography variant="body1" component="div">
                            3. Paste your tracking code and set the placement to "Head"
                        </Typography>
                        <Typography variant="body1" component="div">
                            4. Name your code (e.g., "Analytics Hub Tracking") and save
                        </Typography>
                    </Box>
                    <CodeBlock code={trackingCode} />
                </TabPanel>

                <TabPanel value={tabValue} index={4}>
                    <Typography variant="h6" gutterBottom>
                        Squarespace Installation
                    </Typography>
                    <Box sx={{ pl: 2, mb: 3 }}>
                        <Typography variant="body1" component="div">
                            1. Go to Settings &gt; Advanced &gt; Code Injection
                        </Typography>
                        <Typography variant="body1" component="div">
                            2. Paste your tracking code in the "Header" section
                        </Typography>
                        <Typography variant="body1" component="div">
                            3. Save changes
                        </Typography>
                    </Box>
                    <CodeBlock code={trackingCode} />
                </TabPanel>

                <TabPanel value={tabValue} index={5}>
                    <Typography variant="h6" gutterBottom>
                        Single-Page Applications (React, Vue, Angular)
                    </Typography>

                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 3 }}>
                        React Installation
                    </Typography>
                    <CodeBlock code={reactCode} />

                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 3 }}>
                        Vue Installation
                    </Typography>
                    <CodeBlock code={vueCode} />

                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 3 }}>
                        Angular Installation
                    </Typography>
                    <CodeBlock code={angularCode} />
                </TabPanel>

                <TabPanel value={tabValue} index={6}>
                    <Typography variant="h6" gutterBottom>
                        Google Tag Manager Installation
                    </Typography>
                    <Box sx={{ pl: 2, mb: 3 }}>
                        <Typography variant="body1" component="div">
                            1. Log in to your Google Tag Manager account
                        </Typography>
                        <Typography variant="body1" component="div">
                            2. Create a new tag:
                        </Typography>
                        <Box sx={{ pl: 4 }}>
                            <Typography variant="body1" component="div">
                                • Tag Type: Custom HTML
                            </Typography>
                            <Typography variant="body1" component="div">
                                • HTML: Paste your tracking code
                            </Typography>
                            <Typography variant="body1" component="div">
                                • Triggering: All Pages
                            </Typography>
                        </Box>
                        <Typography variant="body1" component="div">
                            3. Save and publish your container
                        </Typography>
                    </Box>
                    <CodeBlock code={trackingCode} />
                </TabPanel>

                <TabPanel value={tabValue} index={7}>
                    <Typography variant="h6" gutterBottom>
                        Troubleshooting
                    </Typography>

                    <Typography variant="body1" paragraph>
                        If your tracking isn't working properly, check these common issues:
                    </Typography>

                    <Box sx={{ pl: 2, mb: 3 }}>
                        <Typography variant="body1" component="div">
                            1. <strong>Verify the code is in the <code>&lt;head&gt;</code> section</strong> of your website
                        </Typography>
                        <Typography variant="body1" component="div">
                            2. <strong>Check if your website uses caching</strong> and clear it if necessary
                        </Typography>
                        <Typography variant="body1" component="div">
                            3. <strong>Ensure no ad-blockers</strong> are preventing the script from loading
                        </Typography>
                        <Typography variant="body1" component="div">
                            4. <strong>Confirm the domain name</strong> matches exactly what you entered in your Analytics Hub account
                        </Typography>
                        <Typography variant="body1" component="div">
                            5. <strong>Check browser console</strong> for any JavaScript errors
                        </Typography>
                        <Typography variant="body1" component="div">
                            6. <strong>Wait a few minutes</strong> for initial data to appear
                        </Typography>
                    </Box>

                    <Divider sx={{ my: 3 }} />

                    <Typography variant="h6" gutterBottom>
                        Manual Installation (Various Platforms)
                    </Typography>

                    <TableContainer component={Paper} sx={{ mb: 3 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Platform</strong></TableCell>
                                    <TableCell><strong>Where to Add Code</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>HTML/CSS</TableCell>
                                    <TableCell>Before closing <code>&lt;/head&gt;</code> tag</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>PHP</TableCell>
                                    <TableCell>Before <code>?&gt;</code> in header.php</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Node.js/Express</TableCell>
                                    <TableCell>In your view templates</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Webflow</TableCell>
                                    <TableCell>Project Settings &gt; Custom Code &gt; Head Code</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Ghost</TableCell>
                                    <TableCell>Code Injection &gt; Site Header</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>HubSpot</TableCell>
                                    <TableCell>Settings &gt; Website &gt; Pages &gt; Site Header</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box sx={{ textAlign: 'center', mt: 4 }}>
                        <Typography variant="body1" paragraph>
                            Still having trouble with your installation?
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            component={Link}
                            href="mailto:support@analytics-hub.xyz"
                        >
                            Contact Support
                        </Button>
                    </Box>
                </TabPanel>
            </Paper>
        </Container>
    );
};

export default InstallationGuides;
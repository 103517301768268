import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// Create an axios instance with interceptors to handle token refreshing
const apiClient = axios.create({
    baseURL: API_URL
});

// Add a request interceptor to always include the latest token
apiClient.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        console.log(`Request to: ${config.url}`);
        console.log(`Token present: ${token ? 'Yes' : 'No'}`);

        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
            // Add this to debug without exposing full token
            console.log(`Auth header set: Bearer ${token.substring(0, 5)}...`);
        } else {
            console.warn('No token found in localStorage');
        }
        return config;
    },
    error => {
        console.error('Request interceptor error:', error);
        return Promise.reject(error);
    }
);

// Add a response interceptor to handle auth errors
apiClient.interceptors.response.use(
    response => {
        console.log(`Response from ${response.config.url} - Status: ${response.status}`);
        return response;
    },
    error => {
        // If we get a 401, the token might be expired - redirect to login
        if (error.response && error.response.status === 401) {
            console.log('Authentication token expired or invalid - redirecting to login');

            // Only redirect if not already on the login page
            if (!window.location.pathname.includes('/login')) {
                localStorage.removeItem('token');
                // Use a more controlled approach to navigation
                window.location.href = '/login';
            }
        }
        return Promise.reject(error);
    }
);

const dashboardService = {
    // Check authentication status
    isAuthenticated: () => {
        const token = localStorage.getItem('token');
        return !!token;
    },

    // Get list of user's websites
    getUserWebsites: async () => {
        try {
            if (!dashboardService.isAuthenticated()) {
                console.warn('getUserWebsites called without authentication');
                throw new Error('Authentication required');
            }

            const response = await apiClient.get('/websites/');
            return response.data;
        } catch (error) {
            console.error('Error fetching websites:', error);
            throw error;
        }
    },

    // Delete a website
    deleteWebsite: async (websiteId) => {
        try {
            if (!dashboardService.isAuthenticated()) {
                console.warn('deleteWebsite called without authentication');
                throw new Error('Authentication required');
            }

            const response = await apiClient.delete(`/websites/${websiteId}`);
            return response.data;
        } catch (error) {
            console.error('Error deleting website:', error);
            throw error;
        }
    },

    // Get dashboard data for a specific website
    getDashboardData: async (websiteId, timeRange = '7d') => {
        try {
            if (!dashboardService.isAuthenticated()) {
                console.warn('getDashboardData called without authentication');
                throw new Error('Authentication required');
            }

            const response = await apiClient.get(
                `/dashboard/${websiteId}?time_range=${timeRange}`
            );
            return response.data;
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
            throw error;
        }
    },

    // Get outbound links data
    getOutboundLinks: async (websiteId, timeRange = '7d') => {
        try {
            if (!dashboardService.isAuthenticated()) {
                console.warn('getOutboundLinks called without authentication');
                throw new Error('Authentication required');
            }

            const response = await apiClient.get(
                `/dashboard/${websiteId}/outbound-links?time_range=${timeRange}`
            );
            return response.data;
        } catch (error) {
            console.error('Error fetching outbound links:', error);
            throw error;
        }
    },

    // Get error pages data
    getErrorPages: async (websiteId, timeRange = '7d') => {
        try {
            if (!dashboardService.isAuthenticated()) {
                console.warn('getErrorPages called without authentication');
                throw new Error('Authentication required');
            }

            const response = await apiClient.get(
                `/dashboard/${websiteId}/error-pages?time_range=${timeRange}`
            );
            return response.data;
        } catch (error) {
            console.error('Error fetching error pages:', error);
            throw error;
        }
    },

    // Get downloads data
    getDownloads: async (websiteId, timeRange = '7d') => {
        try {
            if (!dashboardService.isAuthenticated()) {
                console.warn('getDownloads called without authentication');
                throw new Error('Authentication required');
            }

            const response = await apiClient.get(
                `/dashboard/${websiteId}/downloads?time_range=${timeRange}`
            );
            return response.data;
        } catch (error) {
            console.error('Error fetching downloads:', error);
            throw error;
        }
    },

    // Get custom events data
    getCustomEvents: async (websiteId, timeRange = '7d') => {
        try {
            if (!dashboardService.isAuthenticated()) {
                console.warn('getCustomEvents called without authentication');
                throw new Error('Authentication required');
            }

            const response = await apiClient.get(
                `/dashboard/${websiteId}/custom-events?time_range=${timeRange}`
            );
            return response.data;
        } catch (error) {
            console.error('Error fetching custom events:', error);
            throw error;
        }
    },

    // Get revenue data
    getRevenue: async (websiteId, timeRange = '7d') => {
        try {
            if (!dashboardService.isAuthenticated()) {
                console.warn('getRevenue called without authentication');
                throw new Error('Authentication required');
            }

            const response = await apiClient.get(
                `/dashboard/${websiteId}/revenue?time_range=${timeRange}`
            );
            return response.data;
        } catch (error) {
            console.error('Error fetching revenue data:', error);
            throw error;
        }
    },

    // Generate insights based on dashboard data
    generateInsights: (websiteId, data) => {
        const insights = [];

        // Add insights based on visitors trend
        if (data.visitors && data.visitors.trend) {
            if (data.visitors.trend > 10) {
                insights.push({
                    type: 'positive',
                    title: 'Visitor growth',
                    description: `Your website has seen a ${data.visitors.trend}% increase in visitors compared to the previous period.`
                });
            } else if (data.visitors.trend < -10) {
                insights.push({
                    type: 'negative',
                    title: 'Visitor decline',
                    description: `Your website has seen a ${Math.abs(data.visitors.trend)}% decrease in visitors compared to the previous period.`
                });
            }
        }

        // Add insights based on bounce rate
        if (data.bounceRate && data.bounceRate.value) {
            if (data.bounceRate.value > 70) {
                insights.push({
                    type: 'negative',
                    title: 'High bounce rate',
                    description: `Your bounce rate of ${data.bounceRate.value}% is higher than the recommended threshold. Consider improving your landing pages.`
                });
            } else if (data.bounceRate.value < 40) {
                insights.push({
                    type: 'positive',
                    title: 'Low bounce rate',
                    description: `Your bounce rate of ${data.bounceRate.value}% is excellent, indicating visitors are engaging with your content.`
                });
            }
        }

        // Add insights based on top pages
        if (data.topPages && data.topPages.length > 0) {
            const topPage = data.topPages[0];
            insights.push({
                type: 'suggestion',
                title: 'Popular content',
                description: `Your most popular page "${topPage.path}" accounts for ${topPage.percentage}% of all traffic. Consider creating similar content.`
            });
        }

        // Add insights based on device breakdown
        if (data.deviceBreakdown && Array.isArray(data.deviceBreakdown)) {
            const mobileDevice = data.deviceBreakdown.find(device => device.name === 'Mobile');
            if (mobileDevice && mobileDevice.value > 60) {
                insights.push({
                    type: 'suggestion',
                    title: 'Mobile-first optimization',
                    description: `${mobileDevice.value}% of your visitors are using mobile devices. Ensure your website is fully optimized for mobile.`
                });
            }
        }

        return insights;
    },

    // Helper method to debug authentication
    checkAuthentication: () => {
        const token = localStorage.getItem('token');
        console.log('Authentication check:');
        console.log(`- Token exists: ${!!token}`);
        if (token) {
            console.log(`- Token starts with: ${token.substring(0, 10)}...`);
            console.log(`- Token length: ${token.length} characters`);
        }
        return !!token;
    }
};

export default dashboardService;
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './HomePage.module.css';
import { useAuth } from '../../contexts/AuthContext';
import OAuthButtons from '../auth/OAuthButtons';

export default function HomePage() {
    const navigate = useNavigate();
    const { isAuthenticated, currentUser } = useAuth();

    useEffect(() => {
        // If user is already authenticated, redirect to websites dashboard
        if (isAuthenticated || currentUser) {
            navigate('/websites');
            return;
        }

        // Prevent scrolling ONLY on the home page
        document.body.style.overflow = 'hidden';
        document.documentElement.style.overflow = 'hidden';

        // Cleanup function to restore scrolling when component unmounts
        return () => {
            document.body.style.overflow = '';
            document.documentElement.style.overflow = '';
        };
    }, [isAuthenticated, currentUser, navigate]);

    return (
        <div className={styles.fullPage}>
            <div className={styles.centeredContent}>
                <div className={styles.logoWrapper}>
                    <img src="/images/charities/AnalyticsLogo.png" alt="Analytics Hub Logo" className={styles.logo} />
                </div>

                <h1 className={styles.title}>
                    <span className={styles.highlight}>Understand your traffic</span>
                    <span className={styles.withAI}>with AI</span>
                </h1>

                <p className={styles.subtitle}>
                    Simple, privacy-focused analytics powered by artificial intelligence.
                </p>

                <p className={styles.description}>
                    No cookies. No personal data. Just clean insights that help you grow.
                </p>

                <div className={styles.buttonGroup}>
                    <button className={styles.login} onClick={() => navigate('/login')}>Login</button>
                    <button className={styles.register} onClick={() => navigate('/register')}>Register</button>
                </div>

                <div className={styles.oauthWrapper}>
                    <OAuthButtons />
                </div>


            </div>
        </div>
    );
}
// components/Support/SupportPage.js
import React, { useState } from 'react';
import { Routes, Route, useNavigate, useParams, Navigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SupportTicketList from './SupportTicketList';
import SupportTicketDetail from './SupportTicketDetail';
import SupportForm from './SupportForm';
import styles from './SupportPage.module.css';

const SupportPage = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <ToastContainer position="top-right" autoClose={5000} />
      <Routes>
        {/* Main route with just the ticket list */}
        <Route
          index
          element={
            <div className={styles.fullWidthContainer}>
              <SupportTicketList
                onSelectTicket={(ticketId) => navigate(`/support/${ticketId}`)}
              />
            </div>
          }
        />

        {/* Route for viewing a specific ticket */}
        <Route
          path=":ticketId"
          element={<TicketDetailView />}
        />

        {/* New ticket form */}
        <Route
          path="new"
          element={
            <div className={styles.formContainer}>
              <SupportForm
                onSubmitSuccess={(ticketId) => {
                  toast.success('Support ticket created successfully');
                  navigate(`/support/${ticketId}`);
                }}
                onCancel={() => navigate('/support')}
              />
            </div>
          }
        />
      </Routes>
    </div>
  );
};

// The view for a specific ticket
const TicketDetailView = () => {
  const { ticketId } = useParams();
  const navigate = useNavigate();

  if (!ticketId || isNaN(parseInt(ticketId, 10))) {
    return <Navigate to="/support" />;
  }

  return (
    <div className={styles.listDetailContainer}>
      {/* Show the list on the left */}
      <div className={styles.listInDetailView}>
        <SupportTicketList
          selectedTicketId={parseInt(ticketId, 10)}
          onSelectTicket={(id) => navigate(`/support/${id}`)}
        />
      </div>

      {/* Show the ticket details on the right */}
      <div className={styles.detailContainer}>
        <SupportTicketDetail
          ticketId={parseInt(ticketId, 10)}
          onBack={() => navigate('/support')}
        />
      </div>
    </div>
  );
};

export default SupportPage;
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import styles from './Header.module.css';

const Header = () => {
    const { isAuthenticated, logout, currentUser } = useAuth();
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();

    // Modified: Show header on all pages, including home page
    // Just use different styling for home page
    const isHomePage = location.pathname === '/';

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className={`${styles.header} ${isHomePage ? styles.homeHeader : ''}`}>
            <div className={styles.container}>
                <div className={styles.logo}>
                    <Link to={isAuthenticated || currentUser ? "/websites" : "/"}>
                        <div className={styles.logoContainer}>
                            <img
                                src="/images/charities/AnalyticsLogo.png"
                                alt="Analytics Hub Logo"
                                className={styles.logoImage}
                            />
                            <span className={styles.logoText}>Analytics Hub</span>
                        </div>
                    </Link>
                </div>

                <div className={styles.mobileMenuButton} onClick={toggleMenu}>
                    <div className={`${styles.hamburgerIcon} ${menuOpen ? styles.open : ''}`}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>

                <nav className={`${styles.nav} ${menuOpen ? styles.navOpen : ''}`}>
                    {isAuthenticated || currentUser ? (
                        <>
                            <Link to="/websites" className={styles.navLink} onClick={() => setMenuOpen(false)}>
                                Dashboard
                            </Link>
                            <Link to="/installation-guides" className={styles.navLink} onClick={() => setMenuOpen(false)}>
                                Guides
                            </Link>
                            <Link to="/support" className={styles.navLink} onClick={() => setMenuOpen(false)}>
                                Support
                            </Link>
                            <button onClick={() => { logout(); setMenuOpen(false); }} className={styles.navButton}>
                                Logout
                            </button>
                        </>
                    ) : (
                        <>
                            <Link to="/login" className={styles.navLink} onClick={() => setMenuOpen(false)}>
                                Login
                            </Link>
                            <Link to="/register" className={styles.navButton} onClick={() => setMenuOpen(false)}>
                                Sign Up
                            </Link>
                        </>
                    )}
                </nav>
            </div>
        </header>
    );
};

export default Header;
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
    Container,
    Typography,
    Box,
    Paper,
    Button,
    Link,
    Dialog,
    DialogContent,
    DialogActions,
    CircularProgress,
    alpha,
    Switch,
    FormControlLabel,
    Collapse,
    Divider,
    Tooltip
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import CodeIcon from '@mui/icons-material/Code';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import WarningIcon from '@mui/icons-material/Warning';
import TagIcon from '@mui/icons-material/Tag';
import BoltIcon from '@mui/icons-material/Bolt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { websiteService } from '../../services/api';
import { Link as RouterLink } from 'react-router-dom';
import AIInstallationHelper from '../AI/AIInstallationHelper';
import { submitSupportTicket } from '../../services/supportService';

// Styled Components
const StepConnector = styled(Box)(({ theme, active }) => ({
    width: '80px',
    height: '2px',
    backgroundColor: active ? theme.palette.primary.main : theme.palette.grey[300],
    margin: '0 auto',
}));



const StepIcon = styled(Box)(({ theme, active, completed }) => ({
    width: 38,
    height: 38,
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: completed
        ? theme.palette.success.main
        : active
            ? theme.palette.primary.main
            : theme.palette.grey[200],
    color: completed || active ? theme.palette.common.white : theme.palette.text.secondary,
    border: `1px solid ${completed
        ? theme.palette.success.main
        : active
            ? theme.palette.primary.main
            : theme.palette.grey[300]}`,
    boxShadow: completed || active ? `0 4px 8px ${alpha(
        completed ? theme.palette.success.main : theme.palette.primary.main, 0.25
    )}` : 'none',
}));

// Using a lighter blue background
const CodeBox = styled(Box)(({ theme }) => ({
    backgroundColor: '#3E5B85',
    borderRadius: '8px',
    color: '#f8f8f2',
    fontFamily: 'monospace',
    fontSize: '0.875rem',
    padding: theme.spacing(2.5),
    paddingTop: theme.spacing(6), // More padding at top for button
    position: 'relative',
    overflowX: 'auto',
    marginBottom: theme.spacing(3),
    boxShadow: 'inset 0 2px 8px rgba(0,0,0,0.2)',
    border: '1px solid rgba(255,255,255,0.1)', // Subtle border
}));

const CopyButton = styled(Button)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(1.5), // More space from top
    right: theme.spacing(1.5), // More space from right
    zIndex: 2,
    minWidth: 'auto',
    padding: theme.spacing(0.5, 1.5),
    fontSize: '0.8rem',
    backgroundColor: 'rgba(255, 255, 255, 0.15)', // Semi-transparent background
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.25)',
    },
}));

// Feature toggle card
const FeatureCard = styled(Paper)(({ theme, active, color = 'primary' }) => ({
    position: 'relative',
    borderLeft: `3px solid ${active ? theme.palette[color].main : theme.palette.grey[300]}`,
    transition: 'all 0.15s ease',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    '&:hover': {
        boxShadow: theme.shadows[2],
        transform: 'translateY(-2px)',
    },
}));

// Toggle button for expanding/collapsing section
const ToggleButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    textTransform: 'none',
    fontWeight: 500,
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    color: theme.palette.text.primary,
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.12),
        borderColor: alpha(theme.palette.primary.main, 0.3),
    }
}));





// Custom switch
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 38,
    height: 22,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 1,
        margin: 1,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.primary.main,
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: theme.palette.primary.main,
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18,
        height: 18,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.grey[400],
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

// Support options container
const SupportOptionsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.background.paper, 0.7),
    backdropFilter: 'blur(8px)',
    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column', // Stack vertically on small screens
        alignItems: 'stretch',   // Make buttons full width
        gap: theme.spacing(1.5)  // Reduce gap between buttons
    }
}));
// Support button
const SupportButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(1.5, 3),
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.2s ease',
    fontSize: '0.9rem',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: theme.shadows[4],
    },
}));

const AIHelpButton = styled(Button)(({ theme }) => ({
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    color: theme.palette.primary.main,
    padding: theme.spacing(1.2, 2.5),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
    transition: 'all 0.2s ease',
    fontSize: '0.9rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.15),
        transform: 'translateY(-2px)',
        boxShadow: theme.shadows[2],
    },
}));

// Enhanced Human Support button
const HumanSupportButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1.2, 2.5),
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.2s ease',
    fontSize: '0.9rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    '&:hover': {
        backgroundColor: theme.palette.warning.dark,
        transform: 'translateY(-2px)',
        boxShadow: theme.shadows[3],
    },
}));


// Cute Robot Icon Component
const CuteRobotIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="7" width="16" height="12" rx="4" fill="#61DAFB" opacity="0.9" />
        <circle cx="9" cy="12" r="2" fill="white" />
        <circle cx="15" cy="12" r="2" fill="white" />
        <rect x="9" y="16" width="6" height="1.5" rx="0.75" fill="white" />
        <path d="M8 4L10 7H14L16 4" stroke="#61DAFB" strokeWidth="1.5" strokeLinecap="round" />
        <rect x="10" y="19" width="4" height="2" fill="#61DAFB" opacity="0.9" />
    </svg>
);

const WebsiteSetup = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [website, setWebsite] = useState(null);
    const [error, setError] = useState(null);
    const [trackingOptions, setTrackingOptions] = useState({
        outboundLinks: false,
        fileDownloads: false,
        errorPages: false,
        hashedPaths: false,
        customEvents: false,
        customProperties: false,
        ecommerceRevenue: false,
    });

    // State for collapsible sections
    const [showOptions, setShowOptions] = useState(false);
    const [copied, setCopied] = useState(false);

    // Verification state
    const [verifying, setVerifying] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState(null);
    const [verificationMessage, setVerificationMessage] = useState('');
    const [verificationDialogOpen, setVerificationDialogOpen] = useState(false);

    // Feature descriptions with icons and colors
    const featureDescriptions = [
        {
            key: 'outboundLinks',
            title: "Outbound Links",
            description: "Track clicks on links to external websites",
            icon: <OpenInNewIcon />,
            color: 'primary'
        },
        {
            key: 'fileDownloads',
            title: "File Downloads",
            description: "Track clicks on file download links",
            icon: <ArrowDownwardIcon />,
            color: 'success'
        },
        {
            key: 'errorPages',
            title: "Error Pages",
            description: "Track visits to 404 pages",
            icon: <WarningIcon />,
            color: 'error'
        },
        {
            key: 'hashedPaths',
            title: "Hashed Paths",
            description: "Track page paths including the hash fragment",
            icon: <TagIcon />,
            color: 'secondary'
        },
        {
            key: 'customEvents',
            title: "Custom Events",
            description: "Track custom events on your site",
            icon: <BoltIcon />,
            color: 'warning'
        },
        {
            key: 'customProperties',
            title: "Custom Properties",
            description: "Add custom properties to events",
            icon: <SettingsIcon />,
            color: 'info'
        },
        {
            key: 'ecommerceRevenue',
            title: "Ecommerce Revenue",
            description: "Track revenue from ecommerce transactions",
            icon: <FlashOnIcon />,
            color: 'success'
        }
    ];

    // Handle navigation to support page with conversation history
    const handleContactSupport = async () => {
        // Store context in sessionStorage for form
        if (website?.id) {
            sessionStorage.setItem('support_website_id', website.id);
        }
        if (website?.domain) {
            sessionStorage.setItem('support_website_domain', website.domain);
        }

        // Navigate to a form page instead of direct submission
        navigate('/support');  // This should be a React route to your support form

        console.log('Navigating to support form with website context:', {
            id: website?.id,
            domain: website?.domain
        });
    };

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 50);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const fetchWebsite = async () => {
            try {
                setLoading(true);
                const response = await websiteService.getWebsite(id);
                console.log("WEBSITE DATA:", response);

                const websiteData = response.data;
                console.log("ACTUAL WEBSITE DATA:", websiteData);

                // Set the website data to state
                setWebsite(websiteData);

                // If the website already has tracking options, use those
                if (websiteData.tracking_options) {
                    setTrackingOptions(websiteData.tracking_options);

                    // If any options are active, expand the section by default
                    const hasActiveOptions = Object.values(websiteData.tracking_options).some(value => value === true);
                    if (hasActiveOptions) {
                        setShowOptions(true);
                    }
                }
            } catch (err) {
                console.error("Error fetching website data:", err);
                setError("Failed to load website information. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        fetchWebsite();
    }, [id]);

    const generateTrackingCode = () => {
        // Get the domain directly from the website object
        const domain = website?.domain || 'your-domain.com';

        let code = `<script defer data-domain="${domain}" `;

        // Add data attributes based on selected options
        if (trackingOptions.outboundLinks) code += 'data-track-outbound="true" ';
        if (trackingOptions.fileDownloads) code += 'data-track-downloads="true" ';
        if (trackingOptions.errorPages) code += 'data-track-errors="true" ';
        if (trackingOptions.hashedPaths) code += 'data-track-hashed="true" ';
        if (trackingOptions.customEvents) code += 'data-track-events="true" ';
        if (trackingOptions.customProperties) code += 'data-track-props="true" ';
        if (trackingOptions.ecommerceRevenue) code += 'data-track-revenue="true" ';

        code += `src="https://analytics-hub.xyz/api/tracking/script.js"></script>`;
        return code;
    };

    const handleCopyCode = () => {
        navigator.clipboard.writeText(generateTrackingCode());
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const toggleFeature = (key) => {
        setTrackingOptions({
            ...trackingOptions,
            [key]: !trackingOptions[key]
        });
    };

    // Count active features
    const activeFeatureCount = Object.values(trackingOptions).filter(value => value === true).length;

    // Handle "Start collecting data" button click
    const handleStartCollecting = async () => {
        setVerifying(true);
        setVerificationDialogOpen(true);

        try {
            // First save the tracking options regardless of status change
            await websiteService.updateWebsite(id, {
                tracking_options: trackingOptions
            });

            // Then verify the installation
            const domain = website?.domain;
            if (!domain) {
                throw new Error('Website domain is missing');
            }

            // Call verification endpoint
            const response = await fetch(`https://analytics-hub.xyz/api/verify-installation?domain=${encodeURIComponent(domain)}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const data = await response.json();

            if (data.success) {
                // If verification successful, use the dedicated status endpoint
                await websiteService.updateWebsiteStatus(id, {
                    status: 'active'
                });

                // Update local website object with new status
                setWebsite({
                    ...website,
                    status: 'active'
                });

                // Set success verification status
                setVerificationStatus('success');
                setVerificationMessage(data.message || 'Your installation is working correctly!');
            } else {
                setVerificationStatus('error');
                setVerificationMessage(data.message || 'Could not verify your installation.');
            }
        } catch (err) {
            console.error('Verification error:', err);
            setVerificationStatus('error');
            setVerificationMessage(err.message || 'Failed to verify installation');
        } finally {
            setVerifying(false);
        }
    };

    // Handle dialog closing based on verification result
    const handleCloseVerification = () => {
        setVerificationDialogOpen(false);

        // If verification was successful, navigate to dashboard
        if (verificationStatus === 'success') {
            navigate(`/dashboard/${id}`);
        }
    };

    // Handle skip verification (still marks as active but doesn't navigate)
    const handleSkipVerification = async () => {
        try {
            setLoading(true);

            // Use the dedicated status endpoint
            await websiteService.updateWebsiteStatus(id, {
                status: 'active'
            });

            // Also save the tracking options with the regular update endpoint
            await websiteService.updateWebsite(id, {
                tracking_options: trackingOptions
            });

            // Update local website object
            setWebsite({
                ...website,
                status: 'active'
            });

            setVerificationDialogOpen(false);
            setVerificationStatus(null);
        } catch (err) {
            console.error('Error updating website status:', err);
        } finally {
            setLoading(false);
        }
    };

    if (loading && !website) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Container
                maxWidth="md"
                sx={{
                    mt: 4,
                    mb: 8, // Add bottom margin to prevent footer overlap
                    pt: { xs: 7, sm: 4 }, // Extra top padding on mobile
                    pb: { xs: 7, sm: 4 }, // Extra bottom padding on mobile
                }}
            >
                <Typography color="error">{error}</Typography>
                <Button
                    variant="contained"
                    onClick={() => window.location.reload()}
                    sx={{ mt: 2 }}
                >
                    Try Again
                </Button>
            </Container>
        );
    }

    return (
        <Container
            maxWidth="md"
            sx={{
                mt: 4,
                mb: { xs: 12, sm: 8 }, // Even more bottom margin on mobile
                pt: { xs: 7, sm: 4 },
                pb: { xs: 8, sm: 4 },
            }}
        >
            {/* Setup Progress - Horizontal Steps with Square Icons */}
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: 5,
                flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on mobile
                alignItems: 'center'
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: 600 }}>
                    {/* Step 1 */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <StepIcon completed={true}>
                            <CheckCircleIcon fontSize="small" />
                        </StepIcon>
                        <Typography variant="body2" sx={{ mt: 1, fontWeight: 500, textAlign: 'center' }}>
                            Add site info
                        </Typography>
                    </Box>

                    {/* Connector */}
                    <StepConnector active={true} />

                    {/* Step 2 */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <StepIcon active={true}>
                            <CodeIcon fontSize="small" />
                        </StepIcon>
                        <Typography variant="body2" sx={{ mt: 1, fontWeight: 600, textAlign: 'center' }}>
                            Install Analytics Hub
                        </Typography>
                    </Box>

                    {/* Connector */}
                    <StepConnector active={false} />

                    {/* Step 3 */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <StepIcon active={false}>
                            <VerifiedUserIcon fontSize="small" />
                        </StepIcon>
                        <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary', textAlign: 'center' }}>
                            Verify installation
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {/* Installation Instructions */}
            <Paper sx={{ p: 3, mb: 3, borderRadius: 2, boxShadow: '0 4px 20px rgba(0,0,0,0.08)' }}>
                <Typography variant="h5" sx={{ mb: 2 }}>Manual installation</Typography>

                <Typography variant="body1" sx={{ mb: 2 }}>
                    Paste this snippet into the <code>&lt;head&gt;</code> section of your site.
                    <Link
                        component={RouterLink}
                        to={`/websites/${id}/installation-guides?domain=${website?.domain || ''}`}
                        sx={{ ml: 1, display: 'inline-flex', alignItems: 'center' }}
                    >
                        See our installation guides <OpenInNewIcon fontSize="small" sx={{ ml: 0.5 }} />
                    </Link>
                </Typography>

                <CodeBox>
                    <CopyButton
                        variant="contained"
                        size="small"
                        color={copied ? "success" : "primary"}
                        onClick={handleCopyCode}
                        startIcon={copied ? <CheckCircleIcon fontSize="small" /> : <ContentCopyIcon fontSize="small" />}
                    >
                        {copied ? 'Copied' : 'Copy'}
                    </CopyButton>
                    <pre style={{
                        margin: 0,
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-all',
                        fontFamily: 'inherit',
                    }}>
                        {generateTrackingCode()}
                    </pre>
                </CodeBox>

                {/* Support Options */}
                <Divider sx={{ my: 3, opacity: 0.6 }} />

                <Typography variant="subtitle1" sx={{ mb: 2, textAlign: 'center', fontWeight: 500 }}>
                    Need assistance with installation?
                </Typography>




                <SupportOptionsContainer>
                    {/* AI Installation Helper button - using the existing AIInstallationHelper component */}
                    <AIInstallationHelper
                        websiteId={id}
                        platform={website?.platform}
                        customRobotIcon={<CuteRobotIcon />}
                        buttonText="AI Installation Help"
                    />

                    {/* Human Support Button - navigating to the correct /support endpoint */}
                    <Tooltip title="Connect with our support team">
                        <SupportButton
                            variant="contained"
                            color="secondary"
                            onClick={handleContactSupport}
                            startIcon={<SupportAgentIcon />}
                        >
                            Connect with Human
                        </SupportButton>
                    </Tooltip>
                </SupportOptionsContainer>

                {/* Expandable Options Section */}
                <Box sx={{ mt: 4 }}>
                    <ToggleButton
                        fullWidth
                        onClick={() => setShowOptions(!showOptions)}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            boxShadow: showOptions ? 2 : 0,
                            bgcolor: (theme) => showOptions ?
                                alpha(theme.palette.primary.main, 0.12) :
                                alpha(theme.palette.primary.main, 0.08),
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            sx={{
                                fontWeight: 500,
                                color: 'text.primary',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1
                            }}
                        >
                            <Box
                                component="span"
                                sx={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '1rem',
                                    color: 'primary.main'
                                }}
                            >
                                {showOptions ?
                                    <ExpandLessIcon fontSize="small" /> :
                                    <ExpandMoreIcon fontSize="small" />
                                }
                            </Box>
                            Enable optional measurements
                        </Typography>
                        {!showOptions && activeFeatureCount > 0 && (
                            <Box sx={{
                                ml: 1,
                                bgcolor: 'primary.main',
                                color: 'white',
                                borderRadius: '50%',
                                width: 20,
                                height: 20,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '0.75rem'
                            }}>
                                {activeFeatureCount}
                            </Box>
                        )}
                    </ToggleButton>

                    <Collapse in={showOptions}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                            {featureDescriptions.map((feature) => (
                                <FeatureCard
                                    key={feature.key}
                                    active={trackingOptions[feature.key]}
                                    color={feature.color}
                                    onClick={() => toggleFeature(feature.key)}
                                    elevation={1}
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}>
                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                            <Box sx={{
                                                color: trackingOptions[feature.key] ? `${feature.color}.main` : 'text.secondary',
                                                display: 'flex',
                                            }}>
                                                {feature.icon}
                                            </Box>
                                            <Box>
                                                <Typography variant="subtitle2" fontWeight={trackingOptions[feature.key] ? 'bold' : 'normal'}>
                                                    {feature.title}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                                                    {feature.description}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <FormControlLabel
                                            control={
                                                <IOSSwitch
                                                    checked={trackingOptions[feature.key]}
                                                    onChange={() => toggleFeature(feature.key)}
                                                    size="small"
                                                />
                                            }
                                            label=""
                                            sx={{ m: 0 }}
                                        />
                                    </Box>
                                </FeatureCard>
                            ))}
                        </Box>
                    </Collapse>
                </Box>
            </Paper>

            {/* Verification Dialog */}
            <Dialog
                open={verificationDialogOpen}
                onClose={verificationStatus ? handleCloseVerification : null}
                maxWidth="sm"
                fullWidth
            >
                <DialogContent sx={{ textAlign: 'center', py: 4 }}>
                    {verifying ? (
                        <>
                            <CircularProgress sx={{ mb: 2 }} />
                            <Typography variant="h6">Verifying your installation</Typography>
                            <Typography color="text.secondary">
                                We're visiting your site to ensure that everything is working
                            </Typography>
                        </>
                    ) : verificationStatus === 'success' ? (
                        <>
                            <Box
                                sx={{
                                    width: 80,
                                    height: 80,
                                    borderRadius: '50%',
                                    bgcolor: 'success.light',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    margin: '0 auto 16px'
                                }}
                            >
                                <CheckCircleIcon sx={{ fontSize: 40, color: 'white' }} />
                            </Box>
                            <Typography variant="h5" sx={{ mb: 2 }}>Success!</Typography>
                            <Typography sx={{ mb: 1 }}>
                                Your installation is working and visitors are being counted accurately
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                                Awaiting your first pageview
                            </Typography>
                        </>
                    ) : verificationStatus === 'error' ? (
                        <>
                            <Box
                                sx={{
                                    width: 80,
                                    height: 80,
                                    borderRadius: '50%',
                                    bgcolor: 'error.light',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    margin: '0 auto 16px'
                                }}
                            >
                                <ErrorOutlineIcon sx={{ fontSize: 40, color: 'white' }} />
                            </Box>
                            <Typography variant="h5" sx={{ mb: 2 }}>Verification Failed</Typography>
                            <Typography sx={{ mb: 3 }}>{verificationMessage}</Typography>
                            <Typography variant="h6" sx={{ mb: 1 }}>Troubleshooting tips:</Typography>
                            <Box sx={{ textAlign: 'left', mb: 2 }}>
                                <Typography component="li">Make sure the script is in the &lt;head&gt; section</Typography>
                                <Typography component="li">Check that your website is publicly accessible</Typography>
                                <Typography component="li">Ensure you've published the latest version of your site</Typography>
                                <Typography component="li">Check for Content Security Policy restrictions</Typography>
                            </Box>
                        </>
                    ) : null}
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
                    {verifying ? null : (
                        <>
                            {verificationStatus === 'success' ? (
                                <Button
                                    variant="contained"
                                    onClick={handleCloseVerification}
                                >
                                    Go to the dashboard
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        onClick={() => {
                                            setVerificationStatus(null);
                                            setVerifying(false);
                                            setVerificationDialogOpen(false);
                                        }}
                                    >
                                        Try again later
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={handleSkipVerification}
                                    >
                                        Skip verification
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                </DialogActions>
            </Dialog>

            <Button
                variant="contained"
                size="large"
                startIcon={<FlashOnIcon />}
                onClick={handleStartCollecting}
                disabled={loading || verifying}
                fullWidth
                sx={{ py: 1.5 }}
            >
                {loading ? 'Processing...' : verifying ? 'Verifying...' : 'Start collecting data'}
            </Button>
        </Container>
    );
};

export default WebsiteSetup;
import React, { useState, useEffect, useCallback } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    LinearProgress
} from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';

// Configuration
const INACTIVE_TIMEOUT = 30 * 60 * 1000; // 30 minutes in milliseconds
const WARNING_TIMEOUT = 2 * 60 * 1000; // Show warning 2 minutes before logout
const COUNTDOWN_INTERVAL = 1000; // Update countdown every second

const SessionTimeout = () => {
    const { logout } = useAuth();
    const [showWarning, setShowWarning] = useState(false);
    const [timeLeft, setTimeLeft] = useState(WARNING_TIMEOUT);
    const [warningTimer, setWarningTimer] = useState(null);
    const [logoutTimer, setLogoutTimer] = useState(null);
    const [countdownTimer, setCountdownTimer] = useState(null);

    // Calculate remaining percentage for progress bar
    const progressPercentage = (timeLeft / WARNING_TIMEOUT) * 100;

    // Reset timers when user activity is detected
    const resetTimers = useCallback(() => {
        if (warningTimer) clearTimeout(warningTimer);
        if (logoutTimer) clearTimeout(logoutTimer);
        if (countdownTimer) clearInterval(countdownTimer);

        setShowWarning(false);

        // Set new warning timer
        const newWarningTimer = setTimeout(() => {
            setShowWarning(true);
            setTimeLeft(WARNING_TIMEOUT);

            // Start countdown timer for warning dialog
            const newCountdownTimer = setInterval(() => {
                setTimeLeft(prevTime => {
                    if (prevTime <= COUNTDOWN_INTERVAL) {
                        clearInterval(newCountdownTimer);
                        return 0;
                    }
                    return prevTime - COUNTDOWN_INTERVAL;
                });
            }, COUNTDOWN_INTERVAL);

            setCountdownTimer(newCountdownTimer);

            // Set logout timer
            const newLogoutTimer = setTimeout(() => {
                setShowWarning(false);
                logout();
            }, WARNING_TIMEOUT);

            setLogoutTimer(newLogoutTimer);
        }, INACTIVE_TIMEOUT - WARNING_TIMEOUT);

        setWarningTimer(newWarningTimer);
    }, [logout, warningTimer, logoutTimer, countdownTimer]);

    // Monitor user activity
    useEffect(() => {
        // Track user activity events
        const activityEvents = [
            'mousedown',
            'mousemove',
            'keydown',
            'scroll',
            'touchstart',
            'click'
        ];

        // Initialize timers on component mount
        resetTimers();

        // Setup event listeners
        activityEvents.forEach(event => {
            window.addEventListener(event, resetTimers);
        });

        // Cleanup event listeners on unmount
        return () => {
            activityEvents.forEach(event => {
                window.removeEventListener(event, resetTimers);
            });

            if (warningTimer) clearTimeout(warningTimer);
            if (logoutTimer) clearTimeout(logoutTimer);
            if (countdownTimer) clearInterval(countdownTimer);
        };
    }, [resetTimers]);

    // Format remaining time for display
    const formatTimeLeft = () => {
        const minutes = Math.floor(timeLeft / 60000);
        const seconds = Math.floor((timeLeft % 60000) / 1000);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    // Stay active when user clicks "Stay Logged In"
    const handleStayActive = () => {
        resetTimers();
    };

    // Logout when user clicks "Logout"
    const handleLogout = () => {
        if (warningTimer) clearTimeout(warningTimer);
        if (logoutTimer) clearTimeout(logoutTimer);
        if (countdownTimer) clearInterval(countdownTimer);

        setShowWarning(false);
        logout();
    };

    return (
        <Dialog
            open={showWarning}
            onClose={handleStayActive}
            aria-labelledby="session-timeout-dialog"
        >
            <DialogTitle id="session-timeout-dialog">
                Session Timeout Warning
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    Your session is about to expire due to inactivity.
                </Typography>
                <Typography variant="body2" gutterBottom>
                    You will be automatically logged out in:
                </Typography>
                <Typography variant="h5" align="center" sx={{ my: 2, fontWeight: 'bold' }}>
                    {formatTimeLeft()}
                </Typography>
                <LinearProgress
                    variant="determinate"
                    value={progressPercentage}
                    sx={{
                        mt: 2,
                        mb: 1,
                        height: 8,
                        borderRadius: 4,
                        backgroundColor: '#f0f0f0',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: timeLeft < 30000 ? '#f44336' : '#2196f3'
                        }
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleLogout} color="secondary">
                    Logout Now
                </Button>
                <Button onClick={handleStayActive} variant="contained" color="primary" autoFocus>
                    Stay Logged In
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SessionTimeout;
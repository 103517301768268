// components/Support/SupportTicketList.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchTickets } from '../../services/supportApi';
import { toast } from 'react-toastify';
import styles from './SupportTicketList.module.css';
import { Clock, MessageSquare, AlertCircle, Check } from 'lucide-react';

const SupportTicketList = ({ selectedTicketId, onSelectTicket }) => {
  const [tickets, setTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  // Fetch support tickets
  useEffect(() => {
    const fetchTicketsData = async () => {
      try {
        setIsLoading(true);
        const data = await fetchTickets();
        setTickets(data);
      } catch (error) {
        toast.error('Failed to load support tickets');
        console.error('Error fetching tickets:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTicketsData();

    // Poll for updates every 30 seconds
    const intervalId = setInterval(fetchTicketsData, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const getStatusIcon = (status) => {
    switch (status) {
      case 'open':
        return <AlertCircle size={16} className={styles.openIcon} />;
      case 'in_progress':
        return <Clock size={16} className={styles.inProgressIcon} />;
      case 'resolved':
        return <Check size={16} className={styles.resolvedIcon} />;
      default:
        return <MessageSquare size={16} />;
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return `Today, ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
    } else if (date.toDateString() === yesterday.toDateString()) {
      return `Yesterday, ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
    } else {
      return date.toLocaleDateString([], { month: 'short', day: 'numeric' });
    }
  };

  if (isLoading) {
    return (
      <div className={styles.loading}>
        Loading support tickets...
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Support Tickets</h2>
        <button
          className={styles.newTicketButton}
          onClick={() => navigate('/support/new')}
        >
          New Ticket
        </button>
      </div>

      {tickets.length === 0 ? (
        <div className={styles.emptyState}>
          <MessageSquare size={48} className={styles.emptyIcon} />
          <p>No support tickets yet</p>
          <button
            className={styles.createButton}
            onClick={() => navigate('/support/new')}
          >
            Create your first ticket
          </button>
        </div>
      ) : (
        <div className={styles.ticketsList}>
          {tickets.map(ticket => (
            <div
              key={ticket.id}
              className={`${styles.ticketItem} ${ticket.id === selectedTicketId ? styles.selected : ''} ${ticket.new_message_count > 0 ? styles.hasNewMessages : ''}`}
              onClick={() => {
                if (typeof onSelectTicket === 'function') {
                  onSelectTicket(ticket.id);
                } else {
                  navigate(`/support/${ticket.id}`);
                }
              }}
            >
              <div className={styles.ticketHeader}>
                <div className={styles.statusIndicator}>
                  {getStatusIcon(ticket.status)}
                  <span className={styles.statusText}>{ticket.status.replace('_', ' ')}</span>
                </div>
                <span className={styles.date}>{formatDate(ticket.created_at)}</span>
              </div>

              <div className={styles.ticketTitle}>{ticket.issue}</div>

              <div className={styles.ticketFooter}>
                {ticket.new_message_count > 0 && (
                  <div className={styles.newMessageBadge}>
                    {ticket.new_message_count} new {ticket.new_message_count === 1 ? 'message' : 'messages'}
                  </div>
                )}
                {ticket.platform && (
                  <div className={styles.platformBadge}>
                    {ticket.platform}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SupportTicketList;
// src/components/Auth/RegisterPage.js
import React, { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
    Container, Box, Avatar, Typography, TextField,
    Button, Grid, Link, Paper, Alert, AlertTitle, CircularProgress
} from '@mui/material';
import { PersonAddOutlined } from '@mui/icons-material';
import { GitHub, Google } from '@mui/icons-material';
import { useAuth } from '../../contexts/AuthContext';
import { authService } from '../../services/api';
import { validateForm, handleOAuthError } from '../../utils/errorHandlers';

const RegisterPage = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        fullName: ''
    });
    const [formErrors, setFormErrors] = useState({});
    const [generalError, setGeneralError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { register, error: authContextError } = useAuth();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Clear field-specific error when user starts typing
        if (formErrors[name]) {
            setFormErrors({ ...formErrors, [name]: '' });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Clear previous errors
        setFormErrors({});
        setGeneralError('');

        // Client-side validation
        const validation = validateForm(formData, 'register');
        if (!validation.isValid) {
            setFormErrors(validation.errors);
            return;
        }

        setIsLoading(true);

        const userData = {
            email: formData.email,
            password: formData.password,
            full_name: formData.fullName,
        };

        try {
            const success = await register(userData);
            if (success) {
                navigate('/login', {
                    state: { message: 'Registration successful! Please login with your new account.' }
                });
            }
        } catch (error) {
            console.error('Registration error:', error);

            // Handle specific error types
            if (error.response) {
                if (error.response.status === 400 || error.response.status === 422) {
                    // Handle validation errors from the server
                    if (error.response.data?.detail === 'Email already registered') {
                        setFormErrors({
                            ...formErrors,
                            email: 'This email is already registered. Please use a different email or try logging in.'
                        });
                    } else if (typeof error.response.data === 'object') {
                        // Map server validation errors to form fields
                        const serverErrors = {};
                        Object.entries(error.response.data).forEach(([key, value]) => {
                            serverErrors[key] = Array.isArray(value) ? value[0] : value;
                        });
                        setFormErrors({ ...formErrors, ...serverErrors });
                    } else {
                        setGeneralError(error.response.data?.detail || 'Invalid registration data. Please check your information.');
                    }
                } else {
                    setGeneralError('An error occurred during registration. Please try again.');
                }
            } else if (error.request) {
                // Request made but no response received
                setGeneralError('Unable to connect to the server. Please check your internet connection.');
            } else {
                // Something else caused the error
                setGeneralError('An unexpected error occurred. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleOAuthLogin = async (provider) => {
        setIsLoading(true);
        setGeneralError('');

        try {
            const response = provider === 'github'
                ? await authService.getGithubLoginUrl()
                : await authService.getGoogleLoginUrl();

            window.location.href = response.data.authorization_url;
        } catch (err) {
            const errorInfo = handleOAuthError(provider, err);
            setGeneralError(errorInfo.message);
            setIsLoading(false);
        }
    };

    return (
        <Container component="main" maxWidth="sm">
            <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <PersonAddOutlined />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>

                    {(generalError || authContextError) && (
                        <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
                            <AlertTitle>Error</AlertTitle>
                            {generalError || authContextError}
                        </Alert>
                    )}

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="fullName"
                            label="Full Name"
                            name="fullName"
                            autoComplete="name"
                            autoFocus
                            value={formData.fullName}
                            onChange={handleChange}
                            error={!!formErrors.fullName}
                            helperText={formErrors.fullName}
                            disabled={isLoading}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={formData.email}
                            onChange={handleChange}
                            error={!!formErrors.email}
                            helperText={formErrors.email}
                            disabled={isLoading}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            value={formData.password}
                            onChange={handleChange}
                            error={!!formErrors.password}
                            helperText={formErrors.password || 'Password must be at least 8 characters long'}
                            disabled={isLoading}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            id="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            error={!!formErrors.confirmPassword}
                            helperText={formErrors.confirmPassword}
                            disabled={isLoading}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={isLoading}
                        >
                            {isLoading ? <CircularProgress size={24} /> : 'Sign Up'}
                        </Button>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link component={RouterLink} to="/login" variant="body2">
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2, width: '100%' }}>
                        <Button
                            fullWidth
                            variant="outlined"
                            startIcon={<GitHub style={{ color: '#24292e' }} />}
                            onClick={() => handleOAuthLogin('github')}
                            sx={{
                                borderColor: '#d0d7de',
                                color: '#24292f',
                                '&:hover': {
                                    borderColor: '#24292e',
                                    bgcolor: 'rgba(36, 41, 46, 0.04)'
                                },
                                py: 1,
                                fontWeight: 500
                            }}
                            disabled={isLoading}
                        >
                            Continue with GitHub
                        </Button>
                        <Button
                            fullWidth
                            variant="outlined"
                            startIcon={<Google />}
                            onClick={() => handleOAuthLogin('google')}
                            sx={{
                                borderColor: '#d0d7de',
                                color: '#4285F4',
                                '&:hover': {
                                    borderColor: '#4285F4',
                                    bgcolor: 'rgba(66, 133, 244, 0.04)'
                                },
                                py: 1,
                                fontWeight: 500
                            }}
                            disabled={isLoading}
                        >
                            Continue with Google
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Container>
    );
};

export default RegisterPage;
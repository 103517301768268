// components/Support/StatusSelector.js
import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, CheckCircle, Clock, AlertCircle } from 'lucide-react';
import styles from './StatusSelector.module.css';

const StatusSelector = ({ currentStatus, onStatusChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  
  const statuses = [
    { id: 'open', label: 'Open', icon: <AlertCircle size={16} /> },
    { id: 'in_progress', label: 'In Progress', icon: <Clock size={16} /> },
    { id: 'resolved', label: 'Resolved', icon: <CheckCircle size={16} /> }
  ];
  
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  const getCurrentStatusInfo = () => {
    return statuses.find(status => status.id === currentStatus) || statuses[0];
  };
  
  const handleSelectStatus = (statusId) => {
    if (statusId !== currentStatus) {
      onStatusChange(statusId);
    }
    setIsOpen(false);
  };
  
  const currentStatusInfo = getCurrentStatusInfo();
  
  return (
    <div className={styles.container} ref={dropdownRef}>
      <button 
        className={styles.selector} 
        onClick={() => setIsOpen(!isOpen)}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        <div className={`${styles.statusBadge} ${styles[currentStatus]}`}>
          {currentStatusInfo.icon}
          <span>{currentStatusInfo.label}</span>
        </div>
        <ChevronDown size={16} className={isOpen ? styles.rotated : ''} />
      </button>
      
      {isOpen && (
        <div className={styles.dropdown} role="listbox">
          {statuses.map(status => (
            <div
              key={status.id}
              className={`${styles.option} ${status.id === currentStatus ? styles.selected : ''}`}
              onClick={() => handleSelectStatus(status.id)}
              role="option"
              aria-selected={status.id === currentStatus}
            >
              <div className={`${styles.statusBadge} ${styles[status.id]}`}>
                {status.icon}
                <span>{status.label}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StatusSelector;

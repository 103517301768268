// components/Support/CollaborationLink.js
import React from 'react';
import { ExternalLink } from 'lucide-react';
import styles from './CollaborationLink.module.css';

const CollaborationLink = ({ url, status, ryzeTicketId }) => {
  return (
    <div className={styles.container}>
      <div className={`${styles.status} ${styles[status]}`}>
        {status.replace('_', ' ')}
      </div>
      
      <div className={styles.ticketId}>
        RYZE Ticket #{ryzeTicketId}
      </div>
      
      <a 
        href={url} 
        target="_blank" 
        rel="noopener noreferrer"
        className={styles.link}
      >
        <ExternalLink size={14} />
        <span>Open in RYZE.ai</span>
      </a>
    </div>
  );
};

export default CollaborationLink;

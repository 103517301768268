// src/components/Support/TestSupportMessages.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getTicketMessages } from '../../services/supportApi';

const TestSupportMessages = () => {
    const { ticketId } = useParams();
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const data = await getTicketMessages(ticketId);
                setMessages(data);
            } catch (error) {
                console.error('Error fetching messages:', error);
            } finally {
                setLoading(false);
            }
        };

        if (ticketId) {
            fetchMessages();
        }
    }, [ticketId]);

    return (
        <div style={{ padding: '20px' }}>
            <h2>Test Support Messages for Ticket #{ticketId}</h2>
            {loading ? (
                <p>Loading messages...</p>
            ) : (
                <div>
                    {messages.length === 0 ? (
                        <p>No messages found for this ticket.</p>
                    ) : (
                        <ul>
                            {messages.map(message => (
                                <li key={message.id}>
                                    <strong>{message.sender_type}:</strong> {message.content}
                                    <br />
                                    <small>{new Date(message.created_at).toLocaleString()}</small>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            )}
        </div>
    );
};

export default TestSupportMessages;
// components/Support/SupportTicketDetail.js
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Send, Paperclip, ArrowLeft, ExternalLink, Info } from 'lucide-react';
import { toast } from 'react-toastify';
import {
  fetchTicket,
  getTicketMessages,
  sendMessage,
  updateTicketStatus,
  uploadAttachment,
  forwardMessageToRyze
} from '../../services/supportApi';
import StatusSelector from './StatusSelector';
import CollaborationLink from './CollaborationLink';
import AttachmentList from './AttachmentList';
import styles from './SupportTicketDetail.module.css';
import axios from 'axios';

const SupportTicketDetail = ({ onBack }) => {
  const { ticketId } = useParams();
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);

  const [ticket, setTicket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [attachments, setAttachments] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fileInputRef = useRef(null);

  // Hide footer when this component is mounted
  useEffect(() => {
    // Find the footer element and hide it
    const footer = document.querySelector('footer');
    if (footer) {
      footer.style.display = 'none';
    }

    // Restore footer visibility when component unmounts
    return () => {
      if (footer) {
        footer.style.display = '';
      }
    };
  }, []);

  // Fetch ticket details (only once when component mounts or ticketId changes)
  useEffect(() => {
    if (!ticketId) return;

    const fetchTicketDetails = async () => {
      try {
        setIsLoading(true);
        const data = await fetchTicket(ticketId);
        setTicket(data);
        setMessages(data.messages || []);
        setAttachments(data.attachments || []);
      } catch (error) {
        toast.error('Failed to load ticket details');
        console.error('Error fetching ticket details:', error);
        navigate('/support');
      } finally {
        setIsLoading(false);
      }
    };

    fetchTicketDetails();

    // No polling setup since the site uses webhooks

  }, [ticketId, navigate]); // Only re-fetch when ticketId changes

  // Listen for webhook events that might update this ticket
  useEffect(() => {
    // Create an event listener for webhook updates
    const handleWebhookUpdate = (event) => {
      // Check if this update is for our current ticket
      if (event.detail && event.detail.ticketId === ticketId) {
        // Refresh ticket data when a webhook event is received
        fetchTicket(ticketId).then(data => {
          setTicket(data);
          setMessages(data.messages || []);
          setAttachments(data.attachments || []);
        }).catch(error => {
          console.error('Error refreshing ticket from webhook event:', error);
        });
      }
    };

    // Listen for custom events that your webhook handler might dispatch
    window.addEventListener('ticket-updated', handleWebhookUpdate);
    window.addEventListener('new-message', handleWebhookUpdate);

    // Cleanup the event listeners
    return () => {
      window.removeEventListener('ticket-updated', handleWebhookUpdate);
      window.removeEventListener('new-message', handleWebhookUpdate);
    };
  }, [ticketId]);

  // Scroll to bottom when messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() && attachments.length === 0) return;

    console.log(`Sending message to ticket ID: ${ticketId}`);
    console.log(`Message content: "${newMessage}"`);

    try {
      setIsSubmitting(true);

      // Check if this ticket has a RYZE collaboration
      const hasRyzeCollaboration = ticket?.collaboration?.ryze_ticket_id;

      if (hasRyzeCollaboration) {
        console.log(`This ticket is linked to RYZE ticket #${ticket.collaboration.ryze_ticket_id}`);
        try {
          // Forward message to RYZE
          await forwardMessageToRyze(ticketId, newMessage);
          console.log("Message successfully forwarded to RYZE");
        } catch (ryzeError) {
          console.error("Error forwarding to RYZE:", ryzeError);
          // Continue with normal message sending even if RYZE forwarding fails
        }
      }

      // Send the message to Analytics Hub
      const response = await sendMessage(ticketId, newMessage);
      console.log('Response from message send:', response);

      // Update the messages list
      setMessages(prevMessages => [...prevMessages, response]);
      setNewMessage('');

      // Refresh ticket data
      const updatedTicket = await fetchTicket(ticketId);
      setTicket(updatedTicket);

    } catch (error) {
      toast.error('Failed to send message');
      console.error('Error sending message:', error);
      console.error('Error details:', error.response?.data || error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  /* Attachment functionality is temporarily disabled
  const handleFileUpload = async (e) => {
    const files = e.target.files;
    if (!files || !files.length) return;

    try {
      const file = files[0];
      setIsSubmitting(true);

      // Upload the file
      const response = await uploadAttachment(ticketId, file);

      // Update attachments list
      setAttachments(prevAttachments => [...prevAttachments, response]);

      toast.success('File uploaded successfully');

      // Clear the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

    } catch (error) {
      toast.error('Failed to upload file');
      console.error('Error uploading file:', error);
    } finally {
      setIsSubmitting(false);
    }
  };
  */

  const handleStatusChange = async (newStatus) => {
    try {
      await updateTicketStatus(ticketId, newStatus);
      setTicket(prev => ({ ...prev, status: newStatus }));
      toast.success(`Ticket status updated to ${newStatus}`);
    } catch (error) {
      toast.error('Failed to update ticket status');
      console.error('Error updating status:', error);
    }
  };

  // Format timestamp
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  };

  // Helper to make URLs clickable
  const makeLinksClickable = (text) => {
    if (!text) return '';

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);
    const matches = text.match(urlRegex) || [];

    return parts.reduce((result, part, i) => {
      result.push(part);

      if (matches[i]) {
        result.push(
          <a
            key={i}
            href={matches[i]}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {matches[i]}
          </a>
        );
      }

      return result;
    }, []);
  };

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loading}>Loading ticket...</div>
      </div>
    );
  }

  if (!ticket) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loading}>Ticket not found</div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {/* Header */}
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <button className={styles.backButton} onClick={onBack || (() => navigate('/support'))}>
            <ArrowLeft size={20} />
            <span className={styles.backText}>Back to tickets</span>
          </button>
        </div>

        <div className={styles.headerRight}>
          <StatusSelector
            currentStatus={ticket.status}
            onStatusChange={handleStatusChange}
          />
          <button
            className={styles.infoButton}
            onClick={() => setShowSidebar(!showSidebar)}
            aria-label={showSidebar ? "Hide ticket details" : "Show ticket details"}
          >
            <Info size={20} />
          </button>
        </div>
      </div>

      <div className={styles.content}>
        {/* Main conversation area */}
        <div className={styles.mainSection}>
          <div className={styles.issueTitle}>
            <h1>{ticket.issue}</h1>
          </div>

          <div className={styles.messagesContainer}>
            {messages.length === 0 ? (
              <div className={styles.emptyMessages}>
                No messages yet.
              </div>
            ) : (
              <>
                {messages.map((message, index) => {
                  const isFromUser = message.sender_type === 'user';
                  const isFromSystem = message.sender_type === 'system';
                  const isFromSupport = message.sender_type === 'support';

                  return (
                    <div
                      key={message.id}
                      className={`${styles.messageWrapper} ${isFromUser
                        ? styles.userMessage
                        : isFromSystem
                          ? styles.systemMessage
                          : styles.supportMessage
                        }`}
                    >
                      {isFromSupport && (
                        <div className={styles.supportIndicator}>RYZE Support</div>
                      )}

                      <div className={styles.messageContent}>
                        <div className={styles.messageText}>
                          {makeLinksClickable(message.content)}
                        </div>
                        <div className={styles.messageTime}>
                          {formatTimestamp(message.created_at)}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div ref={messagesEndRef} />
              </>
            )}
          </div>

          {/* Message input */}
          <div className={styles.inputContainer}>
            <form onSubmit={handleSendMessage} className={styles.inputForm}>
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type your message..."
                className={styles.messageInput}
                disabled={isSubmitting}
              />

              {/* Attachment button is temporarily disabled
              <button
                type="button"
                className={styles.attachButton}
                onClick={() => fileInputRef.current.click()}
                disabled={isSubmitting}
              >
                <Paperclip size={20} />
              </button>

              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileUpload}
                disabled={isSubmitting}
              />
              */}

              <button
                type="submit"
                className={styles.sendButton}
                disabled={isSubmitting}
              >
                <Send size={20} />
              </button>
            </form>
          </div>
        </div>

        {/* Sidebar with ticket details */}
        <div className={`${styles.sidebar} ${showSidebar ? styles.visible : ''}`}>
          <div className={styles.sidebarHeader}>
            <h3>Ticket Details</h3>
            <button
              className={styles.closeSidebar}
              onClick={() => setShowSidebar(false)}
            >
              ×
            </button>
          </div>

          <div className={styles.sidebarContent}>
            <div className={styles.sidebarSection}>
              <h4>Status</h4>
              <div className={`${styles.ticketStatus} ${styles[ticket.status]}`}>
                {ticket.status.replace('_', ' ')}
              </div>
            </div>

            <div className={styles.sidebarSection}>
              <h4>Created</h4>
              <div>{new Date(ticket.created_at).toLocaleString()}</div>
            </div>

            {ticket.email && (
              <div className={styles.sidebarSection}>
                <h4>Contact Email</h4>
                <div>{ticket.email}</div>
              </div>
            )}

            {ticket.platform && (
              <div className={styles.sidebarSection}>
                <h4>Platform</h4>
                <div>{ticket.platform}</div>
              </div>
            )}

            {ticket.source && (
              <div className={styles.sidebarSection}>
                <h4>Source</h4>
                <div>{ticket.source}</div>
              </div>
            )}

            {ticket.collaboration && (
              <div className={styles.sidebarSection}>
                <h4>RYZE.ai Collaboration</h4>
                <CollaborationLink
                  url={ticket.collaboration.collaboration_url}
                  status={ticket.collaboration.status}
                  ryzeTicketId={ticket.collaboration.ryze_ticket_id}
                />
              </div>
            )}

            {/* Attachments section is temporarily disabled
            {attachments && attachments.length > 0 && (
              <div className={styles.sidebarSection}>
                <h4>Attachments</h4>
                <AttachmentList attachments={attachments} />
              </div>
            )}
            */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportTicketDetail;
import React from 'react';

const CuteRobotIcon = ({ width = 24, height = 24, color = "#61DAFB", ...props }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect x="4" y="7" width="16" height="12" rx="4" fill={color} />
        <circle cx="9" cy="12" r="2" fill="white" />
        <circle cx="15" cy="12" r="2" fill="white" />
        <rect x="9" y="16" width="6" height="1.5" rx="0.75" fill="white" />
        <path d="M8 4L10 7H14L16 4" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
        <rect x="10" y="19" width="4" height="2" fill={color} />
    </svg>
);

export default CuteRobotIcon;
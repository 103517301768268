// src/components/Auth/OAuthCallback.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Box, Typography, CircularProgress, Alert, AlertTitle, Button } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../services/api';

const OAuthCallback = ({ provider }) => {
    const [status, setStatus] = useState('loading'); // 'loading', 'success', 'error'
    const [errorMessage, setErrorMessage] = useState('');
    const { handleOAuthCallback } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const processOAuthCallback = async () => {
            try {
                // Get the code and state from URL parameters
                const params = new URLSearchParams(location.search);
                const code = params.get('code');
                const state = params.get('state');
                const error = params.get('error');
                const token = params.get('token');

                // Determine which provider we're using if not explicitly set
                const authProvider = provider ||
                    location.pathname.includes('github') ? 'github' :
                    location.pathname.includes('google') ? 'google' : 'unknown';

                console.log(`Processing ${authProvider} OAuth callback`);
                console.log(`Code: ${code ? code.substring(0, 10) + '...' : 'not present'}`);
                console.log(`State: ${state || 'not present'}`);

                if (error) {
                    // Handle error message from OAuth provider
                    setStatus('error');
                    setErrorMessage(decodeURIComponent(error));
                    return;
                }

                // If we have a token directly in the URL, use it
                if (token) {
                    const success = await handleOAuthCallback(token);
                    if (success) {
                        setStatus('success');
                        setTimeout(() => {
                            navigate('/websites');
                        }, 1500);
                    } else {
                        setStatus('error');
                        setErrorMessage('Failed to authenticate with the provided token.');
                    }
                    return;
                }

                // If we have a code but no token, we need to exchange it on the backend
                if (code && state) {
                    try {
                        console.log(`Exchanging authorization code for token...`);

                        // Make the callback to the appropriate endpoint based on provider
                        const callbackEndpoint = authProvider === 'github'
                            ? '/auth/github/callback'
                            : '/auth/google/callback';

                        // Send the code and state to the backend
                        const response = await api.get(`${callbackEndpoint}?code=${code}&state=${state}`);

                        // Check if the response contains an access token
                        if (response.data && response.data.access_token) {
                            console.log('Received access token from backend');
                            const success = await handleOAuthCallback(response.data.access_token);

                            if (success) {
                                setStatus('success');
                                setTimeout(() => {
                                    navigate('/websites');
                                }, 1500);
                                return;
                            }
                        } else {
                            console.error('No access token in response:', response.data);
                            setStatus('error');
                            setErrorMessage('Authentication failed: No access token received from server.');
                            return;
                        }
                    } catch (exchangeError) {
                        console.error('Error exchanging code for token:', exchangeError);
                        setStatus('error');
                        setErrorMessage('Authentication failed during token exchange. Please try again.');
                        return;
                    }
                }

                // If we get here without returning, we don't have valid auth data
                setStatus('error');
                setErrorMessage('No valid authentication data received. Please try again.');

            } catch (err) {
                console.error('OAuth callback processing error:', err);
                setStatus('error');
                setErrorMessage('An unexpected error occurred. Please try again.');
            }
        };

        processOAuthCallback();
    }, [handleOAuthCallback, location.search, location.pathname, navigate, provider]);

    const handleRetry = () => {
        navigate('/login');
    };

    return (
        <Container component="main" maxWidth="sm">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 4,
                    backgroundColor: 'white',
                    borderRadius: 2,
                    boxShadow: 3
                }}
            >
                {status === 'loading' && (
                    <>
                        <CircularProgress sx={{ mb: 3 }} />
                        <Typography variant="h6">
                            Completing authentication...
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                            Please wait while we verify your credentials.
                        </Typography>
                    </>
                )}

                {status === 'success' && (
                    <Alert severity="success" sx={{ width: '100%' }}>
                        <AlertTitle>Success</AlertTitle>
                        Authentication successful! Redirecting you to your dashboard...
                    </Alert>
                )}

                {status === 'error' && (
                    <>
                        <Alert severity="error" sx={{ width: '100%', mb: 3 }}>
                            <AlertTitle>Authentication Failed</AlertTitle>
                            {errorMessage}
                        </Alert>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleRetry}
                            sx={{ mt: 2 }}
                        >
                            Return to Login
                        </Button>
                    </>
                )}
            </Box>
        </Container>
    );
};

export default OAuthCallback;
// src/contexts/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { authService } from '../services/api';
import { handleAuthError } from '../utils/errorHandlers';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    // Clear error after 5 seconds
    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => {
                setError('');
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [error]);

    // Check if user is authenticated on initial load
    useEffect(() => {
        const checkAuthStatus = async () => {
            const token = localStorage.getItem('auth_token');
            if (token) {
                try {
                    const response = await authService.getCurrentUser();
                    setCurrentUser(response.data);
                    setIsAuthenticated(true);
                } catch (err) {
                    // Token might be invalid or expired
                    console.error('Auth check error:', err);
                    // Only clear tokens if we get a 401 Unauthorized
                    if (err.response && err.response.status === 401) {
                        authService.logout();
                    }
                }
            }
            setLoading(false);
        };

        checkAuthStatus();
    }, []);

    // In the login function in AuthContext.js, add this redirect handling
    const login = async (email, password) => {
        setError('');
        try {
            const data = await authService.login(email, password);
            console.log("Login response data:", data);

            // Make sure data contains user info or fetch it separately
            if (!data.user) {
                // If the login API doesn't return user data, fetch it
                try {
                    const userResponse = await authService.getCurrentUser();
                    setCurrentUser(userResponse.data);
                    console.log("User data set:", userResponse.data);
                } catch (userErr) {
                    console.error("Failed to fetch user data:", userErr);
                    setError('Authentication successful but failed to fetch user data');
                    return false;
                }
            } else {
                setCurrentUser(data.user);
                console.log("User data set:", data.user);
            }

            setIsAuthenticated(true);

            // Get the saved redirect path or default to homepage
            const redirectPath = sessionStorage.getItem('redirectPath') || '/';
            // Clear it so it's not used again
            sessionStorage.removeItem('redirectPath');

            // If on iOS, navigate to a specific path to avoid blank screen
            const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            if (isIOS && redirectPath === '/websites') {
                window.location.href = '/'; // Redirect to home on iOS
            } else {
                window.location.href = redirectPath;
            }

            return true;
        } catch (err) {
            // error handling
            return false;
        }
    };

    const register = async (userData) => {
        setError('');
        try {
            await authService.register(userData);
            return true;
        } catch (err) {
            console.error('Registration error in context:', err);
            const errorInfo = handleAuthError(err);
            setError(errorInfo.message);
            throw err; // Rethrow for component-level handling
        }
    };

    const logout = () => {
        authService.logout();
        setCurrentUser(null);
        setIsAuthenticated(false);
    };

    const handleOAuthCallback = async (token) => {
        setError('');
        try {
            if (await authService.handleOAuthCallback(token)) {
                // Fetch user data after successful OAuth login
                const response = await authService.getCurrentUser();
                setCurrentUser(response.data);
                setIsAuthenticated(true);
                return true;
            }
            return false;
        } catch (err) {
            console.error('OAuth callback error:', err);
            const errorInfo = handleAuthError(err);
            setError(errorInfo.message);
            return false;
        }
    };


    const isTokenExpired = () => {
        const authTimestamp = localStorage.getItem('auth_timestamp');
        if (!authTimestamp) return true;

        const tokenAge = Date.now() - parseInt(authTimestamp, 10);
        const tokenLifespan = 12 * 60 * 60 * 1000; // 12 hours in milliseconds

        return tokenAge > tokenLifespan;
    };

    // Add this to your value object in the return statement
    const value = {
        currentUser,
        isAuthenticated,
        loading,
        error,
        login,
        register,
        logout,
        handleOAuthCallback,
        setError,
        isTokenExpired  // Add this new method
    };


    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
import React, { useState } from 'react';
import { Heart, MessageSquareMore } from 'lucide-react';
import styles from './Footer.module.css';

const Footer = () => {
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [showSupportModal, setShowSupportModal] = useState(false);

    // Handlers to open modals - you'll need to implement the actual modals
    const handleFeedbackClick = () => {
        setShowFeedbackModal(true);
    };

    const handleSupportClick = () => {
        setShowSupportModal(true);
    };

    return (
        <footer className={styles.footer}>
            <div className={styles.footerContent}>
                <div className={styles.section}>
                    {/* <button
                        className={styles.supportButton}
                        onClick={handleSupportClick}
                        aria-label="Support Analytics Hub"
                    >
                        <Heart
                            size={18}
                            className={styles.heartIcon}
                            fill="#ef4444"
                            color="#ef4444"
                        />
                        <span className={styles.buttonText}>Support Analytics Hub</span>
                    </button> */}
                </div>

                <div className={styles.section}>
                    {/* <p className={styles.privacyText}>
                        <Heart size={14} className={styles.smallHeartIcon} />
                        <span>Built for privacy</span>
                    </p> */}
                </div>

                <div className={`${styles.section} ${styles.rightSection}`}>
                    {/* <button
                        className={styles.feedbackButton}
                        onClick={handleFeedbackClick}
                        aria-label="Provide feedback"
                    >
                        <MessageSquareMore
                            size={18}
                            className={styles.feedbackIcon}
                        />
                        <span className={styles.buttonText}>Feedback</span>
                    </button> */}
                </div>
            </div>

            {/* You would add modals here when implemented */}
            {/* Example: 
      {showFeedbackModal && (
        <FeedbackModal onClose={() => setShowFeedbackModal(false)} />
      )}
      
      {showSupportModal && (
        <SupportModal onClose={() => setShowSupportModal(false)} />
      )}
      */}
        </footer>
    );
};

export default Footer;
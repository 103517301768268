// src/utils/errorHandlers.js

/**
 * Maps authentication error responses to user-friendly messages
 */
export const handleAuthError = (error) => {
    // Default error response
    const errorResponse = {
        type: 'unknown_error',
        message: 'An unexpected error occurred. Please try again.',
        details: {},
        originalError: error
    };

    // No response from server (network error)
    if (!error.response) {
        return {
            ...errorResponse,
            type: 'network_error',
            message: 'Unable to connect to the server. Please check your internet connection and try again.'
        };
    }

    // Handle different status codes
    switch (error.response.status) {
        case 400: // Bad request
            return {
                ...errorResponse,
                type: 'validation_error',
                message: error.response.data.detail || 'Invalid input provided. Please check your information and try again.',
                details: error.response.data
            };

        case 401: // Unauthorized
            return {
                ...errorResponse,
                type: 'auth_error',
                message: 'Invalid email or password. Please try again.'
            };

        case 403: // Forbidden
            return {
                ...errorResponse,
                type: 'auth_error',
                message: 'You don\'t have permission to access this resource.'
            };

        case 404: // Not found
            return {
                ...errorResponse,
                type: 'not_found_error',
                message: 'The requested resource was not found.'
            };

        case 422: // Validation error
            return {
                ...errorResponse,
                type: 'validation_error',
                message: 'The information you provided is invalid.',
                details: error.response.data
            };

        case 429: // Too many requests
            return {
                ...errorResponse,
                type: 'rate_limit_error',
                message: 'Too many attempts. Please try again later.'
            };

        case 500: // Server error
        case 502: // Bad gateway
        case 503: // Service unavailable
            return {
                ...errorResponse,
                type: 'server_error',
                message: 'A server error occurred. Please try again later.'
            };

        default:
            return errorResponse;
    }
};

/**
 * Maps form validation errors to user-friendly messages
 */
export const validateForm = (formData, formType = 'login') => {
    const errors = {};

    // Validate email
    if (!formData.email) {
        errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        errors.email = 'Please enter a valid email address';
    }

    // Validate password
    if (!formData.password) {
        errors.password = 'Password is required';
    } else if (formData.password.length < 8) {
        errors.password = 'Password must be at least 8 characters long';
    }

    // Registration-specific validations
    if (formType === 'register') {
        // Validate confirm password
        if (!formData.confirmPassword) {
            errors.confirmPassword = 'Please confirm your password';
        } else if (formData.password !== formData.confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
        }

        // Validate fullName
        if (!formData.fullName) {
            errors.fullName = 'Full name is required';
        }
    }

    return {
        isValid: Object.keys(errors).length === 0,
        errors
    };
};

/**
 * Maps OAuth-specific errors to user-friendly messages
 */
export const handleOAuthError = (provider, error) => {
    // Default error response
    let message = `Failed to authenticate with ${provider}. Please try again.`;

    // Specific provider error handling can be added here
    if (error?.response?.data?.detail) {
        message = error.response.data.detail;
    }

    return {
        type: 'oauth_error',
        message,
        provider,
        originalError: error
    };
};
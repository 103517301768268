import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import assistantApi from '../../services/assistantApi';
import QuickReplies from './QuickReplies';
import { CuteRobotIcon } from '../Common/Icons';
import styles from './AIInstallationHelper.module.css';

const AIInstallationHelper = ({ websiteId: propWebsiteId, platform, customRobotIcon, buttonText }) => {
    const params = useParams();
    // Use websiteId from props or from URL params
    const websiteId = propWebsiteId || params.id;

    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [suggestedActions, setSuggestedActions] = useState([]);
    const [sessionHistory, setSessionHistory] = useState([]);
    const [needsHuman, setNeedsHuman] = useState(false);
    const messageEndRef = useRef(null);
    const navigate = useNavigate();

    // Send initial greeting on first load
    useEffect(() => {
        if (messages.length === 0 && !buttonText) {
            sendMessage("How do I install Analytics Hub on my website?");
        }
    }, [messages, buttonText]);

    // Scroll to bottom of chat when messages change
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleInputChange = (e) => {
        setInputMessage(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (inputMessage.trim()) {
            sendMessage(inputMessage);
            setInputMessage('');
        }
    };

    const sendMessage = async (message) => {
        // Add user message to chat
        const userMessage = { text: message, sender: 'user' };
        setMessages(prevMessages => [...prevMessages, userMessage]);

        // Set loading state
        setIsLoading(true);

        try {
            // Update session history with the user's message
            const updatedHistory = [
                ...sessionHistory,
                { role: 'user', content: message }
            ];

            // Send message to API
            const response = await assistantApi.sendMessage(message, websiteId, updatedHistory);

            // Add assistant response to chat
            const assistantMessage = {
                text: response.response,
                sender: 'assistant',
                actions: response.suggested_actions
            };

            setMessages(prevMessages => [...prevMessages, assistantMessage]);
            setSuggestedActions(response.suggested_actions || []);
            setNeedsHuman(response.needs_human || false);

            // Update session history with the assistant's response
            setSessionHistory([
                ...updatedHistory,
                { role: 'assistant', content: response.response }
            ]);
        } catch (error) {
            console.error('Error sending message:', error);

            // Add error message to chat
            const errorMessage = {
                text: "Sorry, I'm having trouble connecting. Please try again or contact support.",
                sender: 'assistant',
                error: true
            };

            setMessages(prevMessages => [...prevMessages, errorMessage]);
            setSuggestedActions([{
                type: "button",
                text: "Connect with human",
                action: "request_human"
            }]);
            setNeedsHuman(true);
        } finally {
            setIsLoading(false);
        }
    };

    // Store conversation history in sessionStorage when navigating to support
    const handleContactSupport = () => {
        if (sessionHistory.length > 0) {
            try {
                // Store conversation history in sessionStorage
                sessionStorage.setItem('support_conversation_history', JSON.stringify(sessionHistory));
                console.log('Saved conversation history to sessionStorage');
            } catch (error) {
                console.error('Error saving conversation history:', error);
            }
        }

        // Navigate to the form page
        navigate('/support');
    };

    const handleSuggestedAction = (action) => {
        // Special handling for "Connect with human" action
        if (action.text === "Connect with human" || action.action === "request_human") {
            // Store conversation history in sessionStorage before navigating
            if (sessionHistory.length > 0) {
                try {
                    sessionStorage.setItem('support_conversation_history', JSON.stringify(sessionHistory));
                    console.log('Saved conversation history to sessionStorage for support');
                } catch (error) {
                    console.error('Error saving conversation history:', error);
                }
            }

            // Use the correct path that matches NGINX configuration
            navigate('/support');
            return;
        }

        // Handle other actions (existing code)
        if (action.url) {
            navigate(action.url);
            return;
        }

        // Otherwise send as message
        sendMessage(action.text);
    };

    const formatMessageText = (text) => {
        // Handle code blocks with syntax highlighting
        const codeBlockRegex = /```([\s\S]*?)```/g;
        const parts = [];
        let lastIndex = 0;
        let match;

        while ((match = codeBlockRegex.exec(text)) !== null) {
            // Add text before code block
            if (match.index > lastIndex) {
                parts.push(
                    <span key={`text-${lastIndex}`} className={styles.messageText}>
                        {text.substring(lastIndex, match.index)}
                    </span>
                );
            }

            // Add code block
            parts.push(
                <pre key={`code-${match.index}`} className={styles.codeBlock}>
                    <code>{match[1]}</code>
                </pre>
            );

            lastIndex = match.index + match[0].length;
        }

        // Remaining text after last code block
        if (lastIndex < text.length) {
            parts.push(
                <span key={`text-${lastIndex}`} className={styles.messageText}>
                    {text.substring(lastIndex)}
                </span>
            );
        }

        return parts.length > 0 ? parts : text;
    };

    // If buttonText is provided, render just a button
    if (buttonText) {
        return (
            <button
                className={styles.chatButton}
                onClick={() => {
                    // Navigate to an existing route in your App.js
                    if (websiteId) {
                        navigate(`/websites/${websiteId}/installation-help`);
                    } else {
                        // If no website ID, try to use a fallback or default
                        navigate(`/websites/general/installation-help`);
                    }
                }}
            >
                {customRobotIcon ?
                    React.cloneElement(customRobotIcon, { className: styles.buttonIcon }) :
                    <CuteRobotIcon className={styles.buttonIcon} />}
                {buttonText}
            </button>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                {customRobotIcon ?
                    React.cloneElement(customRobotIcon, { className: styles.robotIcon }) :
                    <CuteRobotIcon className={styles.robotIcon} />}
                <h2>Installation Assistant</h2>
            </div>

            <div className={styles.messages}>
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={`${styles.message} ${message.sender === 'user' ? styles.userMessage : styles.assistantMessage
                            } ${message.error ? styles.errorMessage : ''}`}
                    >
                        <div className={styles.messageContent}>
                            {formatMessageText(message.text)}
                        </div>
                    </div>
                ))}

                {isLoading && (
                    <div className={`${styles.message} ${styles.assistantMessage}`}>
                        <div className={styles.typingIndicator}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                )}

                <div ref={messageEndRef} />
            </div>

            {suggestedActions.length > 0 && !isLoading && (
                <QuickReplies
                    actions={suggestedActions}
                    onActionClick={handleSuggestedAction}
                />
            )}

            <form className={styles.inputForm} onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={inputMessage}
                    onChange={handleInputChange}
                    placeholder="Type your message..."
                    className={styles.inputField}
                    disabled={isLoading}
                />
                <button
                    type="submit"
                    className={styles.sendButton}
                    disabled={isLoading || !inputMessage.trim()}
                >
                    Send
                </button>
            </form>

            {needsHuman && (
                <div className={styles.humanSupportBanner}>
                    <p>Would you like to connect with human support?</p>
                    <button
                        className={styles.humanSupportButton}
                        onClick={handleContactSupport} // Use our new handler
                    >
                        Contact Support
                    </button>
                </div>
            )}
        </div>
    );
};

export default AIInstallationHelper;